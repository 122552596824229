import React from "react";
import { useTheme } from "../../theme/ThemeContext";
import {
	RiArrowUpLine,
	RiArrowDownLine,
	RiCalendarLine,
	RiUserLine,
	RiMailLine,
	RiCheckLine,
} from "react-icons/ri";

const DashboardHome = () => {
	const { theme } = useTheme();

	const stats = [
		{
			title: "Total Events",
			value: "24",
			change: "+12%",
			icon: RiCalendarLine,
			positive: true,
		},
		{
			title: "Active RSVPs",
			value: "1,234",
			change: "+8%",
			icon: RiMailLine,
			positive: true,
		},
		{
			title: "Total Guests",
			value: "3,456",
			change: "+15%",
			icon: RiUserLine,
			positive: true,
		},
		{
			title: "Response Rate",
			value: "78%",
			change: "-2%",
			icon: RiCheckLine,
			positive: false,
		},
	];

	return (
		<div className='space-y-8'>
			{/* Page Header */}
			<div className='flex justify-between items-center'>
				<h2 className={`text-2xl font-bold ${theme.text.primary}`}>
					Dashboard Overview
				</h2>
				<select
					className={`px-4 py-2 rounded-lg border ${theme.border.primary} ${theme.background.secondary} ${theme.text.secondary}`}>
					<option>Last 7 days</option>
					<option>Last 30 days</option>
					<option>Last 90 days</option>
				</select>
			</div>

			{/* Stats Grid */}
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'>
				{stats.map((stat) => (
					<div
						key={stat.title}
						className={`p-6 rounded-xl border ${theme.border.primary} ${theme.background.secondary}`}>
						<div className='flex justify-between items-start'>
							<div>
								<p className={`text-sm ${theme.text.tertiary}`}>
									{stat.title}
								</p>
								<p
									className={`text-2xl font-bold mt-1 ${theme.text.primary}`}>
									{stat.value}
								</p>
							</div>
							<div
								className={`p-3 rounded-lg ${theme.accent.background}`}>
								<stat.icon
									className={`w-6 h-6 ${theme.accent.primary}`}
								/>
							</div>
						</div>
						<div className='flex items-center mt-4'>
							{stat.positive ? (
								<RiArrowUpLine className='w-4 h-4 text-emerald-500' />
							) : (
								<RiArrowDownLine className='w-4 h-4 text-rose-500' />
							)}
							<span
								className={`text-sm ml-1 ${
									stat.positive
										? "text-emerald-500"
										: "text-rose-500"
								}`}>
								{stat.change}
							</span>
							<span
								className={`text-sm ml-2 ${theme.text.tertiary}`}>
								vs last period
							</span>
						</div>
					</div>
				))}
			</div>

			<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
				{/* <div className='bg-dark p-6 rounded-lg shadow-lg'>
					<h3 className='text-lg font-medium text-white mb-4'>
						Recent Campaigns
					</h3>
					<div className='space-y-3'>
						{[1, 2, 3].map((campaign) => (
							<div
								key={campaign}
								className='flex items-center justify-between p-3 bg-secondary rounded-lg'>
								<div>
									<p className='text-white'>
										Summer Launch Event
									</p>
									<p className='text-sm text-gray-400'>
										Created 2 days ago
									</p>
								</div>
								<span className='px-3 py-1 text-xs font-medium rounded-full bg-primary/20 text-primary'>
									Active
								</span>
							</div>
						))}
					</div>
				</div> */}

				{/* <div className='bg-dark p-6 rounded-lg shadow-lg'>
					<h3 className='text-lg font-medium text-white mb-4'>
						Recent Activity
					</h3>
					<div className='space-y-4'>
						{[1, 2, 3, 4].map((activity) => (
							<div
								key={activity}
								className='flex items-start space-x-3'>
								<div className='flex-shrink-0'>
									<span className='w-8 h-8 rounded-full bg-primary/20 flex items-center justify-center'>
										📨
									</span>
								</div>
								<div>
									<p className='text-white'>
										New RSVP received
									</p>
									<p className='text-sm text-gray-400'>
										5 minutes ago
									</p>
								</div>
							</div>
						))}
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default DashboardHome;
