import React, { useState, useEffect } from "react";
import { api_dashboard } from "../Api";
import GuestListModal from "../Components/GuestListModal";

const GuestLists = () => {
	const [guestLists, setGuestLists] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [selectedList, setSelectedList] = useState(null);

	// Fetch guest lists
	useEffect(() => {
		fetchGuestLists();
	}, []);

	const fetchGuestLists = async () => {
		try {
			const response = await fetch(api_dashboard.guestLists.list, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
			});
			if (!response.ok) throw new Error("Failed to fetch guest lists");
			const data = await response.json();

			// Check if data is an array, if not, handle accordingly
			const lists = Array.isArray(data) ? data : data.lists || [];
			setGuestLists(lists);
		} catch (err) {
			setError(err.message);
			setGuestLists([]); // Set empty array on error
		} finally {
			setLoading(false);
		}
	};

	const handleCreateList = async (formData) => {
		try {
			const response = await fetch(api_dashboard.guestLists.create, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
				body: JSON.stringify(formData),
			});

			if (!response.ok) throw new Error("Failed to create guest list");
			const newList = await response.json();
			setGuestLists([...guestLists, newList]);
			setShowCreateModal(false);
		} catch (err) {
			setError(err.message);
		}
	};

	const handleDeleteList = async (listId) => {
		if (!window.confirm("Are you sure you want to delete this list?"))
			return;

		try {
			const response = await fetch(
				api_dashboard.guestLists.delete(listId),
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				},
			);
			if (!response.ok) throw new Error("Failed to delete guest list");
			setGuestLists(guestLists.filter((list) => list.id !== listId));
		} catch (err) {
			setError(err.message);
		}
	};

	const handleExportList = async (listId) => {
		try {
			const response = await fetch(
				api_dashboard.guestLists.export(listId),
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				},
			);
			if (!response.ok) throw new Error("Failed to export guest list");
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `guest-list-${listId}.csv`;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
		} catch (err) {
			setError(err.message);
		}
	};

	const handleImport = async (file) => {
		if (!file) return;

		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await fetch(api_dashboard.guestLists.create, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
				body: formData,
			});

			if (!response.ok) throw new Error("Failed to import guest list");

			const newList = await response.json();
			setGuestLists([...guestLists, newList]);
		} catch (err) {
			setError(err.message);
		}
	};

	const handleUpdateList = async (listId, formData) => {
		try {
			const response = await fetch(
				api_dashboard.guestLists.update(listId),
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
					body: JSON.stringify({
						...formData,
						listId,
					}),
				},
			);

			if (!response.ok) throw new Error("Failed to update guest list");
			const updatedList = await response.json();
			setGuestLists(
				guestLists.map((list) =>
					list.id === listId ? updatedList : list,
				),
			);
			setSelectedList(null);
		} catch (err) {
			setError(err.message);
		}
	};

	const handleAddGuest = async (listId, guestData) => {
		try {
			const response = await fetch(
				api_dashboard.guestLists.addGuest(listId),
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
					body: JSON.stringify(guestData),
				},
			);

			if (!response.ok) throw new Error("Failed to add guest");

			const updatedList = await response.json();
			setGuestLists(
				guestLists.map((list) =>
					list.id === listId ? updatedList : list,
				),
			);
		} catch (err) {
			setError(err.message);
		}
	};

	const handleRemoveGuest = async (listId, guestId) => {
		try {
			const response = await fetch(
				api_dashboard.guestLists.removeGuest(listId, guestId),
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				},
			);

			if (!response.ok) throw new Error("Failed to remove guest");

			// Update the guest list in state
			const updatedLists = guestLists.map((list) => {
				if (list.id === listId) {
					return {
						...list,
						guests: list.guests.filter(
							(guest) => guest.id !== guestId,
						),
					};
				}
				return list;
			});
			setGuestLists(updatedLists);
		} catch (err) {
			setError(err.message);
		}
	};

	const scrollbarStyles = `
		<style>
			.custom-scrollbar::-webkit-scrollbar {
				width: 6px;
			}
			
			.custom-scrollbar::-webkit-scrollbar-track {
				background: rgba(31, 41, 55, 0.5);
				border-radius: 10px;
			}
			
			.custom-scrollbar::-webkit-scrollbar-thumb {
				background: rgba(107, 114, 128, 0.5);
				border-radius: 10px;
			}
			
			.custom-scrollbar::-webkit-scrollbar-thumb:hover {
				background: rgba(107, 114, 128, 0.7);
			}
		</style>
	`;

	if (loading) return <div className='text-white'>Loading...</div>;

	return (
		<div className='space-y-6 p-6'>
			<div dangerouslySetInnerHTML={{ __html: scrollbarStyles }} />
			<div className='flex justify-between items-center mb-8'>
				<div>
					<h2 className='text-3xl font-bold text-white'>
						Guest Lists
					</h2>
					<p className='text-gray-400 mt-2'>
						Manage your guest lists and contacts
					</p>
					{error && (
						<div className='mt-2 p-3 bg-red-900/50 border border-red-500 rounded-lg'>
							<span className='text-sm text-red-400'>
								Error loading lists: {error}
							</span>
						</div>
					)}
				</div>
				<div className='space-x-4'>
					<input
						type='file'
						id='fileInput'
						className='hidden'
						onChange={(e) => handleImport(e.target.files[0])}
						accept='.csv,.xlsx'
					/>
					{/* <button
						onClick={() =>
							document.getElementById("fileInput").click()
						}
						className='px-4 py-2 bg-secondary text-white rounded-lg hover:bg-secondary/90 transition-colors duration-200 flex items-center'>
						<svg
							className='w-4 h-4 mr-2'
							fill='none'
							stroke='currentColor'
							viewBox='0 0 24 24'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
							/>
						</svg>
						Import List
					</button> */}
					<button
						onClick={() => setShowCreateModal(true)}
						className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors duration-200 flex items-center'>
						<svg
							className='w-4 h-4 mr-2'
							fill='none'
							stroke='currentColor'
							viewBox='0 0 24 24'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d='M12 6v6m0 0v6m0-6h6m-6 0H6'
							/>
						</svg>
						Create List
					</button>
				</div>
			</div>

			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
				{!error &&
				Array.isArray(guestLists) &&
				guestLists.length > 0 ? (
					guestLists.map((list) => (
						<div
							key={list.id}
							className='bg-dark/50 backdrop-blur-sm border border-gray-800 p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-200 hover:border-gray-700'>
							<div className='flex justify-between items-start'>
								<div className='space-y-2'>
									<h3 className='text-xl font-semibold text-white'>
										{list.name}
									</h3>
									<div className='flex items-center space-x-2'>
										<span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary/10 text-primary'>
											{list.count} contacts
										</span>
										<span className='text-xs text-gray-500'>
											Updated {list.lastUpdated}
										</span>
									</div>
									{list.description && (
										<p className='text-sm text-gray-400 mt-2'>
											{list.description}
										</p>
									)}
								</div>
								<div className='relative'>
									<button
										onClick={() =>
											setSelectedList(
												selectedList === list.id
													? null
													: list.id,
											)
										}
										className='p-2 hover:bg-gray-800 rounded-lg transition-colors duration-200'>
										<svg
											className='w-5 h-5 text-gray-400'
											fill='currentColor'
											viewBox='0 0 24 24'>
											<path d='M12 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z' />
										</svg>
									</button>
									{selectedList === list.id && (
										<div className='absolute right-0 mt-2 w-48 bg-dark rounded-lg shadow-lg py-2 border border-gray-700 z-10'>
											<button
												onClick={() =>
													handleDeleteList(list.id)
												}
												className='w-full text-left px-4 py-2 text-red-500 hover:bg-gray-800 flex items-center'>
												<svg
													className='w-4 h-4 mr-2'
													fill='none'
													stroke='currentColor'
													viewBox='0 0 24 24'>
													<path
														strokeLinecap='round'
														strokeLinejoin='round'
														strokeWidth={2}
														d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'
													/>
												</svg>
												Delete List
											</button>
										</div>
									)}
								</div>
							</div>

							{/* Add guest preview section */}
							<div className='mt-4 border-t border-gray-800'>
								<div className='text-sm text-gray-400 mt-2'>
									Guest Preview:
								</div>
								<div className='mt-2 max-h-32 overflow-y-auto custom-scrollbar'>
									{list.guests && list.guests.length > 0 ? (
										list.guests.map((guest) => (
											<div
												key={guest.id}
												className='py-1 px-2 hover:bg-gray-800/50 rounded-lg'>
												<div className='text-white text-sm'>
													{guest.name}
												</div>
												<div className='text-gray-500 text-xs truncate'>
													{guest.email}
												</div>
											</div>
										))
									) : (
										<div className='text-gray-500 text-sm italic'>
											No guests in this list
										</div>
									)}
								</div>
							</div>

							<div className='mt-6 pt-4 border-t border-gray-800 flex space-x-4'>
								<button
									onClick={() => setSelectedList(list)}
									className='flex items-center text-primary hover:text-primary/80 transition-colors duration-200'>
									<svg
										className='w-4 h-4 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z'
										/>
									</svg>
									Edit
								</button>
								<button
									onClick={() => handleExportList(list.id)}
									className='flex items-center text-primary hover:text-primary/80 transition-colors duration-200'>
									<svg
										className='w-4 h-4 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth={2}
											d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4'
										/>
									</svg>
									Export
								</button>
							</div>
						</div>
					))
				) : (
					<div className='col-span-3 text-center py-12'>
						<div className='bg-dark/50 backdrop-blur-sm border border-gray-800 rounded-xl p-8 max-w-lg mx-auto'>
							<svg
								className='w-16 h-16 text-gray-600 mx-auto mb-4'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth={2}
									d='M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2'
								/>
							</svg>
							<p className='text-gray-400 text-lg mb-4'>
								{error
									? "Unable to load guest lists. You can try creating a new one."
									: "No guest lists found. Create one to get started."}
							</p>
							<button
								onClick={() => setShowCreateModal(true)}
								className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors duration-200 inline-flex items-center'>
								<svg
									className='w-4 h-4 mr-2'
									fill='none'
									stroke='currentColor'
									viewBox='0 0 24 24'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M12 6v6m0 0v6m0-6h6m-6 0H6'
									/>
								</svg>
								Create Your First List
							</button>
						</div>
					</div>
				)}
			</div>

			{/* Add modal */}
			{showCreateModal && (
				<GuestListModal
					list={selectedList}
					onClose={() => {
						setShowCreateModal(false);
						setSelectedList(null);
					}}
					onSubmit={(formData) => {
						if (selectedList) {
							handleUpdateList(selectedList.id, formData);
						} else {
							handleCreateList(formData);
						}
						setShowCreateModal(false);
						setSelectedList(null);
					}}
				/>
			)}
		</div>
	);
};

export default GuestLists;
