import React, { useState, useEffect } from "react";

const GuestListModal = ({ list, onClose, onSubmit }) => {
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		guests: [],
	});
	const [guestInput, setGuestInput] = useState({
		name: "",
		email: "",
		description: "",
	});
	const [error, setError] = useState(null);
	const [uploadMode, setUploadMode] = useState("manual"); // 'manual' or 'csv'

	useEffect(() => {
		if (list) {
			setFormData({
				name: list.name || "",
				description: list.description || "",
				guests: list.guests || [],
			});
		}
	}, [list]);

	const handleAddGuest = () => {
		if (!guestInput.name || !guestInput.email) {
			setError("Name and email are required");
			return;
		}

		setFormData({
			...formData,
			guests: [...formData.guests, guestInput],
		});
		setGuestInput({ name: "", email: "", description: "" });
		setError(null);
	};

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		if (!file) return;

		if (
			!file.type.match(
				"text/csv|application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			)
		) {
			setError("Please upload a CSV or Excel file");
			return;
		}

		const reader = new FileReader();
		reader.onload = (event) => {
			try {
				const text = event.target.result;
				const rows = text.split("\n").map((row) => row.split(","));
				const headers = rows[0].map((header) =>
					header.trim().toLowerCase(),
				);

				// Validate required columns
				const requiredColumns = ["name", "email"];
				const missingColumns = requiredColumns.filter(
					(col) => !headers.includes(col),
				);

				if (missingColumns.length > 0) {
					setError(
						`Missing required columns: ${missingColumns.join(
							", ",
						)}`,
					);
					return;
				}

				// Parse guests from CSV
				const guests = rows
					.slice(1)
					.map((row) => {
						const guest = {};
						headers.forEach((header, index) => {
							guest[header] = row[index]?.trim() || "";
						});
						return guest;
					})
					.filter((guest) => guest.name && guest.email); // Filter out empty rows

				setFormData({
					...formData,
					guests: [...formData.guests, ...guests],
				});
				setError(null);
			} catch (err) {
				setError("Error parsing file. Please check the format.");
			}
		};
		reader.readAsText(file);
	};

	const handleRemoveGuest = (index) => {
		setFormData({
			...formData,
			guests: formData.guests.filter((_, i) => i !== index),
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!formData.name) {
			setError("List name is required");
			return;
		}

		if (!list && formData.guests.length === 0) {
			setError("Add at least one guest to the list");
			return;
		}

		// console.log(formData);
		// return;

		onSubmit(formData);
	};

	return (
		<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4'>
			<div className='bg-dark rounded-lg p-6 w-full max-w-2xl'>
				<h2 className='text-xl font-semibold text-white mb-4'>
					{list ? "Edit Guest List" : "Create New Guest List"}
				</h2>
				<form onSubmit={handleSubmit} className='space-y-4'>
					<div className='grid grid-cols-2 gap-4'>
						<div>
							<label className='block text-sm text-gray-400 mb-1'>
								Name
							</label>
							<input
								type='text'
								value={formData.name}
								onChange={(e) =>
									setFormData({
										...formData,
										name: e.target.value,
									})
								}
								className='w-full bg-gray-800 text-white rounded-lg px-4 py-2 border border-gray-700'
								required
							/>
						</div>
						<div>
							<label className='block text-sm text-gray-400 mb-1'>
								Description
							</label>
							<input
								type='text'
								value={formData.description}
								onChange={(e) =>
									setFormData({
										...formData,
										description: e.target.value,
									})
								}
								className='w-full bg-gray-800 text-white rounded-lg px-4 py-2 border border-gray-700'
							/>
						</div>
					</div>

					<div className='flex space-x-4 mb-4'>
						<button
							type='button'
							onClick={() => setUploadMode("manual")}
							className={`px-4 py-2 rounded-lg ${
								uploadMode === "manual"
									? "bg-primary text-white"
									: "bg-gray-700 text-gray-300"
							}`}>
							Manual Entry
						</button>
						<button
							type='button'
							onClick={() => setUploadMode("csv")}
							className={`px-4 py-2 rounded-lg ${
								uploadMode === "csv"
									? "bg-primary text-white"
									: "bg-gray-700 text-gray-300"
							}`}>
							Upload CSV
						</button>
					</div>

					{uploadMode === "manual" ? (
						<div className='border border-gray-700 rounded-lg p-4'>
							<h3 className='text-white font-medium mb-3'>
								Add Guests
							</h3>
							<div className='grid grid-cols-3 gap-3 mb-3'>
								<input
									type='text'
									placeholder='Name'
									value={guestInput.name}
									onChange={(e) =>
										setGuestInput({
											...guestInput,
											name: e.target.value,
										})
									}
									className='bg-gray-800 text-white rounded-lg px-3 py-2 border border-gray-700'
								/>
								<input
									type='email'
									placeholder='Email'
									value={guestInput.email}
									onChange={(e) =>
										setGuestInput({
											...guestInput,
											email: e.target.value,
										})
									}
									className='bg-gray-800 text-white rounded-lg px-3 py-2 border border-gray-700'
								/>
								<input
									type='text'
									placeholder='Description (optional)'
									value={guestInput.description}
									onChange={(e) =>
										setGuestInput({
											...guestInput,
											description: e.target.value,
										})
									}
									className='bg-gray-800 text-white rounded-lg px-3 py-2 border border-gray-700'
								/>
							</div>
							<button
								type='button'
								onClick={handleAddGuest}
								className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90'>
								Add Guest
							</button>
						</div>
					) : (
						<div className='border border-gray-700 rounded-lg p-4'>
							<h3 className='text-white font-medium mb-3'>
								Upload Guest List
							</h3>
							<input
								type='file'
								onChange={handleFileUpload}
								accept='.csv,.xlsx,.xls'
								className='w-full bg-gray-800 text-white rounded-lg px-4 py-2 border border-gray-700'
							/>
							<p className='text-sm text-gray-500 mt-2'>
								Upload a CSV file with columns: name (required),
								email (required), description (optional)
							</p>
						</div>
					)}

					{formData.guests.length > 0 && (
						<div className='border border-gray-700 rounded-lg p-4'>
							<div className='flex justify-between items-center mb-3'>
								<h3 className='text-white font-medium'>
									Guest List Preview
								</h3>
								<span className='text-gray-400 text-sm'>
									Total Guests: {formData.guests.length}
								</span>
							</div>
							<div className='overflow-x-auto'>
								<table className='w-full text-sm'>
									<thead>
										<tr className='text-gray-400 border-b border-gray-700'>
											<th className='text-left py-2 px-3'>
												Name
											</th>
											<th className='text-left py-2 px-3'>
												Email
											</th>
											<th className='text-left py-2 px-3'>
												Description
											</th>
											<th className='text-right py-2 px-3'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{formData.guests
											.slice(0, 5)
											.map((guest, index) => (
												<tr
													key={index}
													className='border-b border-gray-700/50'>
													<td className='py-2 px-3 text-white'>
														{guest.name}
													</td>
													<td className='py-2 px-3 text-white'>
														{guest.email}
													</td>
													<td className='py-2 px-3 text-gray-400'>
														{guest.description}
													</td>
													<td className='py-2 px-3 text-right'>
														<button
															type='button'
															onClick={() =>
																handleRemoveGuest(
																	index,
																)
															}
															className='text-red-500 hover:text-red-400'>
															Remove
														</button>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
							{formData.guests.length > 5 && (
								<p className='text-gray-400 text-sm mt-3 text-center'>
									Showing first 5 of {formData.guests.length}{" "}
									guests
								</p>
							)}
						</div>
					)}

					{error && <p className='text-red-500 text-sm'>{error}</p>}

					<div className='flex justify-end space-x-3 mt-6'>
						<button
							type='button'
							onClick={onClose}
							className='px-4 py-2 text-gray-400 hover:text-white'>
							Cancel
						</button>
						<button
							type='submit'
							className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90'>
							{list ? "Update" : "Create"}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default GuestListModal;
