import React from "react";

const Analytics = () => {
	const metrics = [
		{
			name: "Total Invitations",
			value: "1,234",
			change: "+12.5%",
			trend: "up",
		},
		{
			name: "Response Rate",
			value: "76%",
			change: "+5.2%",
			trend: "up",
		},
		{
			name: "Attendance Rate",
			value: "82%",
			change: "-2.1%",
			trend: "down",
		},
		{
			name: "Avg Response Time",
			value: "2.3 days",
			change: "-0.5 days",
			trend: "up",
		},
	];

	return (
		<div className='space-y-6'>
			<div className='flex justify-between items-center'>
				<h2 className='text-2xl font-semibold text-white'>Analytics</h2>
				<select className='bg-dark text-white px-4 py-2 rounded-lg border border-gray-700'>
					<option>Last 30 days</option>
					<option>Last 90 days</option>
					<option>Last 12 months</option>
				</select>
			</div>

			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
				{metrics.map((metric) => (
					<div
						key={metric.name}
						className='bg-dark p-6 rounded-lg shadow-lg'>
						<dt className='text-sm font-medium text-gray-400'>
							{metric.name}
						</dt>
						<dd className='mt-1 text-3xl font-semibold text-white'>
							{metric.value}
						</dd>
						<p
							className={`mt-2 text-sm ${
								metric.trend === "up"
									? "text-green-500"
									: "text-red-500"
							}`}>
							{metric.change}
						</p>
					</div>
				))}
			</div>

			<div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
				<div className='bg-dark p-6 rounded-lg shadow-lg'>
					<h3 className='text-lg font-medium text-white mb-4'>
						Response Trends
					</h3>
					<div className='h-64 flex items-center justify-center text-gray-500'>
						Chart placeholder
					</div>
				</div>

				<div className='bg-dark p-6 rounded-lg shadow-lg'>
					<h3 className='text-lg font-medium text-white mb-4'>
						Campaign Performance
					</h3>
					<div className='h-64 flex items-center justify-center text-gray-500'>
						Chart placeholder
					</div>
				</div>
			</div>
		</div>
	);
};

export default Analytics;
