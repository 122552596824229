let e = `https://us-central1-traq-e3acf.cloudfunctions.net/app`;

// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
// 	e = `http://localhost:5000/traq-e3acf/us-central1/app`;
// }

// let e = `https://traq.app/REST/dashboard_api`;

// RSVP endpoints
export const api_rsvp = {
	events: e + "/rsvp/campaigns",
	eventById: (id) => e + `/rsvp/events/${id}`,
	guestList: (id) => e + `/rsvp/events/${id}/guests`,
	updateGuestStatus: (id) => e + `/rsvp/guests/${id}/status`,
	eventStats: (id) => e + `/rsvp/events/${id}/stats`,
	exportGuests: (id) => e + `/rsvp/events/${id}/export`,
	createCampaign: e + "/rsvp/campaigns",
	guestLists: {
		list: e + "/rsvp/guest-lists",
		get: (id) => e + `/rsvp/guest-lists/${id}`,
		create: e + "/rsvp/guest-lists",
		update: (id) => e + `/rsvp/guest-lists/${id}`,
		delete: (id) => e + `/rsvp/guest-lists/${id}`,
		import: (id) => e + `/rsvp/guest-lists/${id}/import`,
		export: (id) => e + `/rsvp/guest-lists/${id}/export`,
		guests: (id) => e + `/rsvp/guest-lists/${id}/guests`,
		addGuest: (id) => e + `/rsvp/guest-lists/${id}/guests`,
		removeGuest: (listId, guestId) =>
			e + `/rsvp/guest-lists/${listId}/guests/${guestId}`,
	},
	templates: {
		list: e + "/rsvp/templates",
		get: (id) => e + `/rsvp/templates/${id}`,
		create: e + "/rsvp/templates",
		update: (id) => e + `/rsvp/templates/${id}`,
		delete: (id) => e + `/rsvp/templates/${id}`,
		preview: (id) => e + `/rsvp/templates/${id}/preview`,
	},
};

// Dashboard API endpoints
export const api_dashboard = {
	login: e + "/rsvp/login",
	campaigns: e + "/rsvp/campaigns",
	guests: api_rsvp.guestList,
	templates: api_rsvp.templates,
	analytics: api_rsvp.analytics,
	createCampaign: api_rsvp.createCampaign,
	guestLists: api_rsvp.guestLists,
	domains: {
		list: "/api/domains",
		create: "/api/domains",
		update: (id) => `/api/domains/${id}`,
		delete: (id) => `/api/domains/${id}`,
	},
	rsvps: {
		list: e + "/rsvp/events/rsvps",
		getByEvent: (eventId) => e + `/rsvp/events/${eventId}/rsvps`,
		exportToGuestList: e + "/rsvp/events/export-to-guestlist",
		exportCSV: (eventId) => e + `/rsvp/events/${eventId}/export-csv`,
	},
};
