import React, { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import NewCampaignForm from "../Components/NewCampaignForm";
import { useTheme } from "../../theme/ThemeContext";
import moment from "moment";
import {
	RiMailLine,
	RiMoreLine,
	RiAddLine,
	RiEditLine,
	RiDeleteBinLine,
	RiPauseLine,
	RiPlayLine,
} from "react-icons/ri";
import { api_dashboard } from "../Api";

const Campaigns = () => {
	const [campaigns, setCampaigns] = useState([]);
	const [showNewCampaignForm, setShowNewCampaignForm] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const { theme } = useTheme();
	const [sendingCampaignId, setSendingCampaignId] = useState(null);
	const [showSendingAnimation, setShowSendingAnimation] = useState(false);

	const sendingAnimationData = {
		v: "5.5.7",
		fr: 30,
		ip: 0,
		op: 60,
		w: 100,
		h: 100,
		assets: [],
		layers: [
			{
				ddd: 0,
				ind: 1,
				ty: 4,
				nm: "circle",
				sr: 1,
				ks: {
					o: {
						a: 1,
						k: [
							{
								t: 0,
								s: [100],
								e: [20],
							},
							{
								t: 30,
								s: [20],
								e: [100],
							},
							{
								t: 60,
								s: [100],
							},
						],
					},
					s: {
						a: 1,
						k: [
							{
								t: 0,
								s: [100, 100],
								e: [80, 80],
							},
							{
								t: 30,
								s: [80, 80],
								e: [100, 100],
							},
							{
								t: 60,
								s: [100, 100],
							},
						],
					},
				},
				shapes: [
					{
						ty: "el",
						p: { a: 0, k: [50, 50] },
						s: { a: 0, k: [80, 80] },
						c: { a: 0, k: [0.3, 0.7, 1] },
					},
				],
			},
		],
	};

	const fetchCampaigns = async () => {
		try {
			setLoading(true);
			console.log("Fetching campaigns from:", api_dashboard.campaigns);

			const response = await axios.get(api_dashboard.campaigns, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
			});

			console.log("Campaign response:", response.data);
			setCampaigns(response.data || []);
			setError(null);
		} catch (err) {
			console.error("Error fetching campaigns:", err);
			setError(
				err.response?.data?.message ||
					"Failed to fetch campaigns. Please check your connection and try again.",
			);
			setCampaigns([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchCampaigns();
	}, []);

	const handleCampaignCreated = async (newCampaign) => {
		await fetchCampaigns();
	};

	const getStatusColor = (status) => {
		switch (status.toLowerCase()) {
			case "sent":
				return "bg-green-100 text-green-600";
			case "scheduled":
				return "bg-blue-100 text-blue-600";
			case "sending":
				return "bg-yellow-100 text-yellow-600";
			case "draft":
				return "bg-gray-100 text-gray-600";
			case "failed":
				return "bg-red-100 text-red-600";
			default:
				return "bg-gray-100 text-gray-600";
		}
	};

	const handleSendCampaign = async (campaignId) => {
		try {
			setSendingCampaignId(campaignId);
			setShowSendingAnimation(true);

			await axios.post(
				"https://workflow.tetrice.co.za/webhook/1910ecb9-99b8-4e8b-9686-d42da02bab4a",
				{
					campaignId,
					action: "send",
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				},
			);

			await new Promise((resolve) => setTimeout(resolve, 2000));

			setCampaigns((prevCampaigns) =>
				prevCampaigns.map((campaign) =>
					campaign.id === campaignId
						? { ...campaign, status: "sent" }
						: campaign,
				),
			);
		} catch (error) {
			console.error("Error sending campaign:", error);
		} finally {
			setSendingCampaignId(null);
			setShowSendingAnimation(false);
		}
	};

	const handleStatusToggle = async (campaignId, currentStatus) => {
		if (currentStatus.toLowerCase() === "scheduled") {
			await handleSendCampaign(campaignId);
			return;
		}

		let newStatus;
		switch (currentStatus.toLowerCase()) {
			case "draft":
				newStatus = "scheduled";
				break;
			case "scheduled":
				newStatus = "sending";
				break;
			case "sending":
				newStatus = "scheduled";
				break;
			default:
				newStatus = "draft";
		}

		try {
			// Add API call here to update status
			// await axios.patch(`${api_dashboard.campaigns}/${campaignId}/status`, { status: newStatus });
			await fetchCampaigns();
		} catch (error) {
			console.error("Error updating campaign status:", error);
		}
	};

	const getStatusIcon = (status) => {
		switch (status.toLowerCase()) {
			case "draft":
				return (
					<RiPlayLine
						className='w-5 h-5 text-blue-500'
						title='Schedule Campaign'
					/>
				);
			case "scheduled":
				return (
					<RiPlayLine
						className='w-5 h-5 text-green-500'
						title='Send Now'
					/>
				);
			case "sending":
				return (
					<RiPauseLine
						className='w-5 h-5 text-yellow-500'
						title='Pause Sending'
					/>
				);
			case "sent":
				return null;
			default:
				return (
					<RiPlayLine
						className='w-5 h-5 text-gray-500'
						title='Reschedule Campaign'
					/>
				);
		}
	};

	if (loading) {
		return (
			<div className='flex items-center justify-center h-64'>
				<div className='text-gray-400'>Loading campaigns...</div>
			</div>
		);
	}

	if (error) {
		return (
			<div className='bg-red-500/10 border border-red-500/50 text-red-500 p-4 rounded-lg'>
				{error}
			</div>
		);
	}

	return (
		<div className='space-y-8 relative'>
			{showSendingAnimation && (
				<div className='fixed inset-0 bg-black/50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-lg p-6 flex flex-col items-center gap-4'>
						<Lottie
							animationData={sendingAnimationData}
							loop={true}
							style={{ width: 100, height: 100 }}
						/>
						<p className='text-lg font-medium'>
							Sending Campaign...
						</p>
					</div>
				</div>
			)}

			<div className='flex justify-between items-center'>
				<h2 className={`text-2xl font-bold ${theme.text.primary}`}>
					Campaigns
				</h2>
				<button
					onClick={() => setShowNewCampaignForm(true)}
					className={`px-4 py-2 rounded-lg flex items-center gap-2 ${theme.highlight.background} ${theme.text.primary}`}>
					<RiAddLine className='w-5 h-5' />
					New Campaign
				</button>
			</div>

			<div
				className={`rounded-xl border ${theme.border.primary} ${theme.background.secondary} overflow-hidden`}>
				<table className='w-full'>
					<thead className={`${theme.background.tertiary}`}>
						<tr>
							<th
								className={`px-6 py-4 text-left text-sm font-medium ${theme.text.secondary}`}>
								Campaign Name
							</th>
							<th
								className={`px-6 py-4 text-left text-sm font-medium ${theme.text.secondary}`}>
								Status
							</th>
							<th
								className={`px-6 py-4 text-left text-sm font-medium ${theme.text.secondary}`}>
								Performance
							</th>
							<th
								className={`px-6 py-4 text-left text-sm font-medium ${theme.text.secondary}`}>
								Date
							</th>
							<th
								className={`px-6 py-4 text-left text-sm font-medium ${theme.text.secondary}`}>
								Actions
							</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200'>
						{campaigns.map((campaign) => (
							<tr
								key={campaign.id}
								className={`${theme.background.hover}`}>
								<td
									className={`px-6 py-4 ${theme.text.primary}`}>
									<div className='flex items-center gap-3'>
										<div
											className={`p-2 rounded-lg ${theme.accent.background}`}>
											<RiMailLine
												className={`w-5 h-5 ${theme.accent.primary}`}
											/>
										</div>
										<div>
											<div className='font-medium'>
												{campaign.name}
											</div>
											<div
												className={`text-sm ${theme.text.secondary}`}>
												Guest list:{" "}
												{campaign.guestlistTitle ||
													"N/A"}{" "}
											</div>
											<div
												className={`text-sm ${theme.text.secondary}`}>
												<span
													className={`px-3 py-1 rounded-full text-sm ${getStatusColor(
														campaign.status,
													)}`}>
													{campaign.recipientCount}
												</span>
											</div>
										</div>
									</div>
								</td>
								<td className={`px-6 py-4`}>
									<span
										className={`px-3 py-1 rounded-full text-sm ${getStatusColor(
											campaign.status,
										)}`}>
										{campaign.status}
									</span>
								</td>

								<td className={`px-6 py-4`}>
									<div className='space-y-2'>
										<div className='flex items-center gap-2'>
											<span
												className={`text-sm ${theme.text.secondary}`}>
												Opened
											</span>
											<div className='flex-1 h-2 bg-gray-100 rounded-full'>
												<div
													className='h-full bg-blue-500 rounded-full'
													style={{
														width: `${
															(campaign.opened /
																campaign.sent) *
															100
														}%`,
													}}
												/>
											</div>
											<span
												className={`text-sm ${theme.text.secondary}`}>
												{Math.round(
													(campaign.opened /
														campaign.sent) *
														100,
												)}
												%
											</span>
										</div>
										<div className='flex items-center gap-2'>
											<span
												className={`text-sm ${theme.text.secondary}`}>
												Responded
											</span>
											<div className='flex-1 h-2 bg-gray-100 rounded-full'>
												<div
													className='h-full bg-green-500 rounded-full'
													style={{
														width: `${
															(campaign.responded /
																campaign.sent) *
															100
														}%`,
													}}
												/>
											</div>
											<span
												className={`text-sm ${theme.text.secondary}`}>
												{Math.round(
													(campaign.responded /
														campaign.sent) *
														100,
												)}
												%
											</span>
										</div>
									</div>
								</td>
								<td
									className={`px-6 py-4 ${theme.text.secondary}`}>
									{moment(campaign.sendDate).format(
										"DD/MM/YYYY",
									)}
								</td>
								<td className={`px-6 py-4`}>
									<div className='flex items-center gap-2'>
										{campaign.status.toLowerCase() !==
											"sent" && (
											<button
												onClick={() =>
													handleStatusToggle(
														campaign.id,
														campaign.status,
													)
												}
												disabled={
													sendingCampaignId ===
													campaign.id
												}
												className={`p-2 rounded-lg hover:bg-gray-100 ${
													sendingCampaignId ===
													campaign.id
														? "opacity-50 cursor-not-allowed"
														: ""
												}`}
												title={
													campaign.status === "draft"
														? "Schedule Campaign"
														: campaign.status ===
														  "scheduled"
														? "Send Now"
														: campaign.status ===
														  "sending"
														? "Pause Sending"
														: "Reschedule Campaign"
												}>
												{getStatusIcon(campaign.status)}
											</button>
										)}
										<button
											className={`p-2 rounded-lg hover:bg-gray-100`}
											title='Delete Campaign'>
											<RiDeleteBinLine
												className={`w-5 h-5 text-red-500`}
											/>
										</button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{showNewCampaignForm && (
				<NewCampaignForm
					onClose={() => setShowNewCampaignForm(false)}
					onCampaignCreated={handleCampaignCreated}
				/>
			)}
		</div>
	);
};

export default Campaigns;
