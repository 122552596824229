import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	Image,
	ScrollView,
	TouchableOpacity,
	Modal,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import "./index.css";
import "./overlay.css";
import * as animationData from "./animation_ln08boza.json";
import topImage from "./PadelEventAssets/padel_top.png";
import bottomImage from "./PadelEventAssets/padel_bottom.png";
import RsvpFormN8n from "./RSVPForm/RsvpFormN8n";
import moment from "moment";

const AutoPlayVideo = () => {
	return (
		<View
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 1,
				overflow: "hidden",
				width: "100%",
				height: "auto",
			}}>
			<video
				autoPlay
				loop
				muted
				playsInline
				style={{
					width: "100%",
					height: "auto",
					// maxWidth: 450,
					zIndex: 1,
				}}>
				<source
					src={require("./PadelEventAssets/young-sportswoman-and-man-couple-playing-padel-ten-2023-11-27-05-25-54-utc.mp4")}
					type='video/mp4'
				/>
				Your browser does not support the video tag.
			</video>

			<div className='overlay2'></div>
			<div className='overlay'></div>
		</View>
	);
};

export default class RsvpTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: "",
			eventTitle: "Padel Event",
			user_data: "",
			pageLoaded: false,
			expired: moment("21/06/2024").isBefore(moment()),
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				pageLoaded: true,
			});
		}, 200);

		localStorage.clear();

		document.title = this.state.eventTitle;

		this.getLocalData();

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});

		this.setState();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getLocalData = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		console.log(userData);
		if (typeof userData !== "undefined" && userData !== null) {
			this.setState({
				userData: userData,
			});
		}
	};

	render() {
		if (this.state.expired && this.state.userData === "") {
			return <View></View>;
		} else
			return (
				<View
					style={{
						width: this.state.screenWidth,
						// // overflow: "hidden",
						height: this.state.screenHeight,
						// flex: 1,
					}}>
					{/* <Fade left>
					<View
						style={{
							position: "absolute",
							left: 0,
						}}>
						<Image
							source={require("./ColorStayAssets/group_left.png")}
							style={{
								width: 200,
								height: window.innerHeight,
								resizeMode: "cover",
							}}
						/>
					</View>
				</Fade> */}

					{this.state.rsvpForm ? this.rsvpForm() : null}
					{/* {this.loadingScreenInitial()} */}

					{/* <Fade right>
					<View
						style={{
							position: "absolute",
							right: 0,
						}}>
						<Image
							source={require("./ColorStayAssets/group_right.png")}
							style={{
								width: 200,
								height: window.innerHeight,
								resizeMode: "cover",
							}}
						/>
					</View>
				</Fade> */}
					<View
						style={{
							flex: 1,
							// width: "100%",
							maxWidth: 500,
							// alignItems: "center",
							// backgroundColor: "red",
							marginHorizontal: "auto",
							overflow: "hidden",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<ScrollView showsVerticalScrollIndicator={false}>
								<View
									style={{
										// position: "absolute",
										// left: this.state.screenWidth > 700 ? "30%" : "5%",
										// width: "100%",
										alignItems: "center",
										// margin: 10,

										zIndex: 800,
									}}>
									{this.state.userData === "" ? (
										<View>
											{/* <Image
										source={card}
										style={{
											// width: window.in,
											// height: window.innerHeight * 0.8,
											height: window.innerHeight,
											width: window.innerWidth * 0.75,
											maxWidth: 350,
											resizeMode: "contain",
										}}
									/> */}
											<View
												style={{
													flex: 1,
													alignItems: "center",
													justifyContent: "center",
												}}>
												{this.state.pageLoaded ? (
													<AutoPlayVideo />
												) : null}
												<View
													style={{
														width: "100%",
														height: 400,
														maxWidth: 450,
														resizeMode: "contain",
														position: "absolute",
														top: 0,
														// left: 0,
														zIndex: 800,
														// backgroundColor: "red",
													}}>
													<Image
														source={topImage}
														style={{
															width: "100%",
															height: 400,
															maxWidth: 450,
															resizeMode:
																"contain",
															position:
																"absolute",
															top: 0,
															// left: 0,
															zIndex: 800,
														}}
													/>
												</View>
												<View
													style={{
														flex: 1,
														// backgroundColor: "red",
														padding: 10,
														alignItems: "center",
														justifyContent:
															"center",
														marginTop: 360,
													}}>
													<View
														style={{
															justifyContent:
																"center",
															alignItems:
																"center",
															marginVertical: 50,
															justifyContent:
																"center",
														}}>
														<Text
															style={{
																fontWeight:
																	"900",
																fontSize: 29,
																fontFamily:
																	"Futura",
																color: "#0078B0",
																textAlign:
																	"center",
															}}>
															Become the best team
															player you can be!
														</Text>
													</View>
													<View
														style={{
															justifyContent:
																"center",
															alignItems:
																"center",
															marginVertical: 20,
														}}>
														<Text
															style={{
																fontWeight:
																	"400",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
															}}>
															You are invited
														</Text>
														<Text
															style={{
																fontWeight:
																	"400",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
															}}>
															to participate in
															the 2024
														</Text>
														<Text
															style={{
																fontWeight:
																	"900",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
																marginBottom: 10,
															}}>
															One in Beauty Padel
															Challenge
														</Text>

														<Text
															style={{
																fontWeight:
																	"400",
																fontSize: 19,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
															}}>
															RSVP by 21/06/2024
														</Text>

														<View
															style={{
																justifyContent:
																	"center",
																alignItems:
																	"center",
																marginVertical: 50,
															}}>
															<Text
																style={{
																	fontWeight:
																		"900",
																	fontSize: 20,
																	fontFamily:
																		"Gotham",
																	textAlign:
																		"center",
																	marginTop: 10,
																}}>
																Location:
															</Text>
															<Text
																style={{
																	fontWeight:
																		"400",
																	fontSize: 20,
																	fontFamily:
																		"Gotham",
																	textAlign:
																		"center",
																}}>
																@ JOGO Padel -
																Honey Rock
																Hotel, Honeydew
															</Text>
															<View>
																<iframe
																	src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14334.843536084723!2d27.9204893!3d-26.0757035!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959f107334126f%3A0xcf437e722341c68c!2sJogo%20Padel!5e0!3m2!1sen!2sza!4v1718126245468!5m2!1sen!2sza'
																	width={
																		window.innerWidth *
																			0.9 >
																		500
																			? 500
																			: window.innerWidth *
																			  0.9
																	}
																	height='300'
																	style={{
																		border: 0,
																	}}
																	allowFullScreen=''
																	loading='lazy'
																	referrerPolicy='no-referrer-when-downgrade'></iframe>
															</View>
														</View>
													</View>

													<View
														style={{
															justifyContent:
																"center",
															alignItems:
																"center",
															marginVertical: 20,
														}}>
														<Text
															style={{
																marginVertical: 20,
																fontSize: 19,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
															}}>
															Select one of the
															following dates
															thats best suit your
															schedule. Event
															starts at 1pm.
														</Text>

														{[
															"28 June 2024",
															"5 July 2024",
															"12 July 2024",
														].map((d) => (
															<TouchableOpacity
																onPress={() => {
																	this.setState(
																		{
																			rsvpForm: true,
																			selectedDate:
																				d,
																		},
																	);
																}}
																style={{
																	marginVertical: 10,
																	backgroundColor:
																		"#0078B0",
																	padding: 15,
																	borderRadius: 10,
																	width: "100%",
																	justifyContent:
																		"center",
																	alignItems:
																		"center",
																}}>
																<Text
																	style={{
																		color: "#FFF",
																		fontSize: 18,
																		fontWeight:
																			"500",
																	}}>
																	{d}
																</Text>
															</TouchableOpacity>
														))}

														<Text
															style={{
																marginVertical: 20,
																fontSize: 19,
																fontFamily:
																	"Gotham",
															}}>
															* Please note that
															the final date will
															be taken from the
															most popular time
															slot selected by
															participants.
														</Text>
													</View>
												</View>
												<Image
													source={bottomImage}
													style={{
														// width: window.in,
														// height: window.innerHeight * 0.8,
														// height: window.innerHeight,
														// width: window.innerWidth * 0.75,
														width:
															window.innerWidth *
																0.9 >
															500
																? 500
																: window.innerWidth *
																  0.9,
														height:
															window.innerWidth *
																0.9 >
															500
																? 500
																: window.innerWidth *
																  0.9 *
																  0.75,
														maxWidth: 450,
														resizeMode: "contain",
													}}
												/>
											</View>
										</View>
									) : (
										<View>
											{/* <Image
										source={card}
										style={{
											// width: window.in,
											// height: window.innerHeight * 0.8,
											height: window.innerHeight,
											width: window.innerWidth * 0.75,
											maxWidth: 350,
											resizeMode: "contain",
										}}
									/> */}
											<View
												style={{
													flex: 1,
													alignItems: "center",
													justifyContent: "center",
												}}>
												<AutoPlayVideo
													pageLoaded={
														this.state.pageLoaded
													}
												/>

												<Image
													source={topImage}
													style={{
														width: "100%",
														height: 400,
														maxWidth: 450,
														resizeMode: "contain",
														position: "absolute",
														top: 0,
														// left: 0,
														zIndex: 800,
													}}
												/>
												<View
													style={{
														flex: 1,
														// backgroundColor: "red",
														padding: 10,
														alignItems: "center",
														justifyContent:
															"center",
														marginTop: 360,
													}}>
													<View
														style={{
															justifyContent:
																"center",
															alignItems:
																"center",
															marginVertical: 20,
														}}>
														<Text
															style={{
																fontWeight:
																	"400",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
															}}>
															You're RSVP has been
															submitted for the
														</Text>

														<Text
															style={{
																fontWeight:
																	"900",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
																marginTop: 10,
															}}>
															One in Beauty Padel
															Challenge
														</Text>
													</View>

													<View
														style={{
															justifyContent:
																"center",
															alignItems:
																"center",
															marginVertical: 20,
														}}>
														<Text
															style={{
																fontWeight:
																	"900",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
																marginTop: 10,
															}}>
															Location:
														</Text>
														<Text
															style={{
																fontWeight:
																	"400",
																fontSize: 25,
																fontFamily:
																	"Gotham",
																textAlign:
																	"center",
															}}>
															@ JOGO Padel - Honey
															Rock Hotel, Honeydew
														</Text>
														<View>
															<iframe
																src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14334.843536084723!2d27.9204893!3d-26.0757035!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959f107334126f%3A0xcf437e722341c68c!2sJogo%20Padel!5e0!3m2!1sen!2sza!4v1718126245468!5m2!1sen!2sza'
																width={
																	window.innerWidth *
																		0.9 >
																	500
																		? 500
																		: window.innerWidth *
																		  0.9
																}
																height='300'
																style={{
																	border: 0,
																}}
																allowFullScreen=''
																loading='lazy'
																referrerPolicy='no-referrer-when-downgrade'></iframe>
														</View>
													</View>

													<View
														style={{
															justifyContent:
																"center",
															alignItems:
																"center",
															marginVertical: 20,
														}}>
														<Text
															style={{
																marginVertical: 20,
																fontSize: 19,
																fontFamily:
																	"Gotham",
															}}>
															* Please note that
															the final date will
															be taken from the
															most popular time
															slot selected by
															participants.
														</Text>
													</View>
												</View>
												<Image
													source={bottomImage}
													style={{
														// width: window.in,
														// height: window.innerHeight * 0.8,
														// height: window.innerHeight,
														// width: window.innerWidth * 0.75,
														width:
															window.innerWidth *
																0.9 >
															500
																? 500
																: window.innerWidth *
																  0.9,
														height:
															window.innerWidth *
																0.9 >
															500
																? 500
																: window.innerWidth *
																  0.9 *
																  0.75,
														maxWidth: 450,
														resizeMode: "contain",
													}}
												/>
											</View>
										</View>
									)}
								</View>
							</ScrollView>
						</View>
					</View>
				</View>
			);
	}

	loadingScreenInitial = () => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={!this.state.pageLoaded}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#FFF",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<View
						style={{
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							// backgroundColor: "#FFF9FB",
							position: "absolute",
							top: 0,
						}}>
						{/* <img
							src={require("./ColorStayAssets/colorbg.png")}
							alt='Description'
							className='responsive'
						/> */}
						{/* <Image
							source={require("./ColorStayAssets/colorbg.png")}
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								resizeMode: "contain",
							}}
						/> */}
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							flex: 1,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#FFF",
								padding: 10,
								borderRadius: 10,
								paddingHorizontal: 20,
							}}>
							{/* <Image
								source={require("./ColorStayAssets/revlon_colo.png")}
								style={{
									width: 200,
									height: 200,
									resizeMode: "contain",
								}}
							/> */}
							<ActivityIndicator color='#000' size={"small"} />
							<Text
								style={{
									fontSize: 16,
									fontWeight: "600",
									color: "#000",
									fontFamily: "Gotham",
									letterSpacing: -1,
								}}>
								LOADING INVITE
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	rsvpForm = () => {
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={true}
				onRequestClose={() => {
					this.setState({
						rsvpForm: !this.state.rsvpForm,
					});
					this.getLocalData();
				}}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#FFF",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<RsvpFormN8n
						title={this.state.eventTitle}
						selectedDate={this.state.selectedDate}
						close={() => {
							this.setState({
								rsvpForm: !this.state.rsvpForm,
							});

							this.getLocalData();
						}}
					/>
				</View>
			</Modal>
		);
	};
}
