const colors = {
	dark: {
		// Core background layers
		background: {
			primary: "bg-slate-900", // Main app background
			secondary: "bg-slate-800", // Card/surface background
			tertiary: "bg-slate-800/50", // Subtle elevation
			hover: "hover:bg-slate-700/50",
			gradient: "from-slate-900 to-slate-900/95",
		},
		// Interactive elements and focus states
		accent: {
			primary: "text-indigo-400",
			secondary: "text-indigo-300",
			background: "bg-indigo-500/10",
			border: "border-indigo-500/20",
			hover: "hover:bg-indigo-500/15",
			shadow: "shadow-indigo-500/10",
			focus: "focus:ring-indigo-500/50",
			hoverBg: "hover:bg-indigo-500/20",
			hoverText: "hover:text-indigo-300",
		},
		// Call-to-action and important states
		highlight: {
			primary: "text-emerald-400",
			secondary: "text-emerald-500",
			background: "bg-emerald-500",
			backgroundLight: "bg-emerald-500/10",
			hover: "hover:bg-emerald-600",
			focus: "focus:ring-emerald-500/50",
		},
		// Text hierarchy
		text: {
			primary: "text-slate-50", // High contrast - headings
			secondary: "text-slate-300", // Medium contrast - body
			tertiary: "text-slate-400", // Low contrast - supporting
			disabled: "text-slate-500", // Inactive states
		},
		// Border definitions
		border: {
			primary: "border-slate-700",
			secondary: "border-slate-600",
			focus: "focus:ring-2",
		},
		// Status colors
		status: {
			success: "text-emerald-400",
			error: "text-rose-400",
			warning: "text-amber-400",
			info: "text-sky-400",
		},
	},
	light: {
		// Core background layers
		background: {
			primary: "bg-slate-50",
			secondary: "bg-white",
			tertiary: "bg-slate-100/50",
			hover: "hover:bg-slate-100/50",
			gradient: "from-slate-50 to-white",
		},
		// Interactive elements and focus states
		accent: {
			primary: "text-indigo-600",
			secondary: "text-indigo-700",
			background: "bg-indigo-50",
			border: "border-indigo-200",
			hover: "hover:bg-indigo-100",
			shadow: "shadow-indigo-200/10",
			focus: "focus:ring-indigo-600/50",
			hoverBg: "hover:bg-indigo-100",
			hoverText: "hover:text-indigo-600",
		},
		// Call-to-action and important states
		highlight: {
			primary: "text-emerald-600",
			secondary: "text-emerald-700",
			background: "bg-emerald-600",
			backgroundLight: "bg-emerald-50",
			hover: "hover:bg-emerald-700",
			focus: "focus:ring-emerald-600/50",
		},
		// Text hierarchy
		text: {
			primary: "text-slate-900",
			secondary: "text-slate-700",
			tertiary: "text-slate-600",
			disabled: "text-slate-400",
		},
		// Border definitions
		border: {
			primary: "border-slate-200",
			secondary: "border-slate-300",
			focus: "focus:ring-2",
		},
		// Status colors
		status: {
			success: "text-emerald-600",
			error: "text-rose-600",
			warning: "text-amber-600",
			info: "text-sky-600",
		},
	},
};

export default colors;
