import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	Image,
	ScrollView,
	TouchableOpacity,
	Modal,
} from "react-native";
import Fade from "react-reveal/Fade";
import "./index.css";
import moment from "moment";
import RsvpForm from "./RSVPForm/RsvpForm";
import { motion, AnimatePresence } from "framer-motion";
// import Confetti from "react-confetti";

// Default images
import defaultLeftImg from "./EventAssets/Web asset 2 - Ribbon 5.webp";
import defaultRightImg from "./EventAssets/Web asset 2 - Ribbon 5.webp";
import defaultCardImg from "./EventAssets/juicy_box.webp";
import defaultAcceptedImg from "./EventAssets/juicy_bottle.webp";
import backgroundImage from "./EventAssets/Web asset 3 - Background 4.webp";

// Add this console log to verify the image is loading
console.log("Background image path:", backgroundImage);

export default class EventPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: "",
			eventData: null,
			loading: true,
			error: null,
			rsvpForm: false,
			cardAnimating: false,
			cardRevealed: false,
			bounceInterval: null,
			showInviteText: false,
		};
	}

	componentDidMount() {
		document.title = "Event Invitation";
		this.getLocalData();
		this.fetchEventData();

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		// Start the bounce animation interval
		const bounceInterval = setInterval(() => {
			if (!this.state.cardAnimating && !this.state.cardRevealed) {
				this.triggerBounce();
			}
		}, 10000);

		this.setState({ bounceInterval });

		// Add auto-trigger for card animation after 5 seconds
		setTimeout(() => {
			if (!this.state.cardRevealed) {
				this.startCardAnimation();
			}
		}, 5000);
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));

		// Clear the bounce interval when component unmounts
		if (this.state.bounceInterval) {
			clearInterval(this.state.bounceInterval);
		}
	}

	getLocalData = () => {
		const userData = JSON.parse(
			localStorage.getItem(`userData_${this.props.eventId}`),
		);
		if (typeof userData !== "undefined" && userData !== null) {
			this.setState({
				userData: userData,
			});
		}
	};

	fetchEventData = async () => {
		try {
			this.setState({ loading: true });

			// For testing, create mock data if API is not available
			const mockEventData = {
				title: `Event ${this.props.eventId}`,
				leftDecoration: defaultLeftImg,
				rightDecoration: defaultRightImg,
				invitationCard: defaultCardImg,
				acceptedCard: defaultAcceptedImg,
			};

			// Try to fetch from API, fallback to mock data
			try {
				const response = await fetch(
					`/api/events/${this.props.eventId}`,
				);

				if (!response.ok) {
					throw new Error("Failed to fetch event data");
				}

				const eventData = await response.json();

				this.setState({
					eventData,
					loading: false,
					error: null,
				});
			} catch (apiError) {
				console.log("Using mock data:", apiError);
				this.setState({
					eventData: mockEventData,
					loading: false,
					error: null,
				});
			}

			// Update document title with event name
			if (this.state.eventData?.title) {
				document.title = this.state.eventData.title;
			}
		} catch (error) {
			console.error("Error in event page:", error);
			this.setState({
				error: "Unable to load event details. Please try again later.",
				loading: false,
			});
		}
	};

	triggerBounce = () => {
		// First jump up
		this.setState({ isBouncing: "up" });

		// Then fall back down with spring effect
		setTimeout(() => {
			this.setState({ isBouncing: "down" });
		}, 400);

		// Reset after animation completes
		setTimeout(() => {
			this.setState({ isBouncing: false });
		}, 500);
	};

	startCardAnimation = () => {
		// Clear the bounce interval when animation starts
		if (this.state.bounceInterval) {
			clearInterval(this.state.bounceInterval);
			this.setState({ bounceInterval: null });
		}

		this.setState({ cardAnimating: true });

		// After animation completes, set cardRevealed to true
		setTimeout(() => {
			this.setState({ cardRevealed: true });

			// Add timer to show invite text and hide accepted card
			setTimeout(() => {
				this.setState({ showInviteText: true });
			}, 5000);
		}, 500);
	};

	openRsvpForm = () => {
		this.setState({ rsvpForm: true });
	};

	render() {
		const {
			loading,
			error,
			eventData,
			userData,
			cardAnimating,
			cardRevealed,
			isBouncing,
			showInviteText,
		} = this.state;

		if (loading) {
			return this.renderLoading();
		}

		if (error) {
			return this.renderError();
		}

		if (!eventData) {
			return this.renderError("Event not found");
		}

		// Card animation variants for Framer Motion
		const frontCardVariants = {
			initial: { y: 0 },
			animate: {
				y: -window.innerHeight,
				transition: { duration: 1.5, ease: "easeInOut" },
			},
			up: {
				y: -40,
				transition: {
					duration: 0.4,
					ease: "easeOut",
				},
			},
			down: {
				y: 0,
				transition: {
					type: "spring",
					stiffness: 300,
					damping: 15,
					duration: 0.8,
				},
			},
		};

		// Ribbon animation variants
		const ribbonVariants = {
			initial: { scale: 0, rotate: -180 },
			animate: {
				scale: 1,
				rotate: 0,
				transition: {
					type: "spring",
					stiffness: 260,
					damping: 20,
					duration: 1.5,
				},
			},
			hover: {
				scale: 1.05,
				rotate: 5,
				transition: {
					duration: 0.3,
				},
			},
		};

		const buttonVariants = {
			hidden: { opacity: 0, y: 20 },
			visible: {
				opacity: 1,
				y: 0,
				transition: { delay: 1.2, duration: 0.5 },
			},
		};

		const backgroundCardVariants = {
			initial: { scale: 1 },
			revealed: {
				scale: 1.2,
				transition: {
					delay: 0.5,
					duration: 0.8,
					type: "spring",
					stiffness: 100,
					damping: 15,
				},
			},
		};

		const textVariants = {
			hidden: { opacity: 0, y: 20 },
			visible: {
				opacity: 1,
				y: 0,
				transition: { duration: 0.3 },
			},
		};

		return (
			<>
				<div className='background-container'>
					<div className='background-image'></div>
				</div>
				<div
					style={{
						width: "100vw",
						height: "100vh",
						overflow: "hidden",
						position: "relative",
						backgroundColor: "transparent",
					}}>
					{/* Content Container */}
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
							zIndex: 1,
						}}>
						{/* Left decoration */}
						<motion.div
							initial='initial'
							animate='animate'
							whileHover='hover'
							variants={ribbonVariants}
							style={{
								position: "absolute",
								left: 0,
								height: "100%",
								zIndex: 2,
							}}>
							<img
								src={eventData.leftDecoration || defaultLeftImg}
								alt='Left decoration'
								style={{
									width: 200,
									height: "100%",
									objectFit: "cover",
								}}
							/>
						</motion.div>

						{this.state.rsvpForm ? this.rsvpForm() : null}

						{/* Right decoration */}
						<motion.div
							initial='initial'
							animate='animate'
							whileHover='hover'
							variants={ribbonVariants}
							style={{
								position: "absolute",
								right: 0,
								height: "100%",
								zIndex: 2,
							}}>
							<img
								src={
									eventData.rightDecoration || defaultRightImg
								}
								alt='Right decoration'
								style={{
									width: 200,
									height: "100%",
									objectFit: "cover",
								}}
							/>
						</motion.div>

						<div
							style={{
								height: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								position: "relative",
								zIndex: 3,
							}}>
							{this.state.userData === "" ? (
								<>
									{/* Background card (revealed when front card animates up) */}
									<motion.div
										initial='initial'
										animate={
											cardRevealed
												? "revealed"
												: "initial"
										}
										variants={backgroundCardVariants}
										style={{
											position: "absolute",
											zIndex: 4,
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
										}}>
										{!this.state.showInviteText && (
											<img
												src={
													eventData.acceptedCard ||
													defaultAcceptedImg
												}
												alt='Accepted card'
												style={{
													height: "auto",
													width: Math.min(
														window.innerWidth *
															0.75,
														350,
													),
													maxWidth: 350,
													objectFit: "contain",
												}}
											/>
										)}

										{this.state.showInviteText && (
											<motion.div
												initial='hidden'
												animate='visible'
												variants={textVariants}
												style={{
													maxWidth: 600,
													padding:
														window.innerWidth < 768
															? "15px"
															: "20px",
													textAlign: "center",
													color: "#000",
													fontFamily:
														"Gotham, sans-serif",
													backgroundColor: "#FFF",
													borderRadius:
														window.innerWidth < 768
															? 15
															: 20,
													boxShadow:
														"0px 4px 8px rgba(0, 0, 0, 0.2)",
													margin:
														window.innerWidth < 768
															? "0 15px"
															: 0,
													overflowY: "auto",
													maxHeight: "90vh",
												}}>
												<h1
													style={{
														fontSize:
															window.innerWidth <
															768
																? "20px"
																: "24px",
														marginBottom:
															window.innerWidth <
															768
																? "15px"
																: "20px",
														fontWeight: "bold",
														padding:
															window.innerWidth <
															768
																? "0 10px"
																: 0,
													}}>
													YOU'RE INVITED TO CHECK IN
													TO THE #JUICYLIFE HOTEL
												</h1>
												<p
													style={{
														fontSize:
															window.innerWidth <
															768
																? "16px"
																: "18px",
														marginBottom:
															window.innerWidth <
															768
																? "15px"
																: "20px",
														lineHeight: "1.6",
														padding:
															window.innerWidth <
															768
																? "0 5px"
																: 0,
													}}>
													Welcome to the #JuicyLife
													Hotel: The ultimate escape
													where OTT glamour,
													unapologetic femininity and
													fantasy collide.
												</p>
												<p
													style={{
														fontSize:
															window.innerWidth <
															768
																? "16px"
																: "18px",
														marginBottom:
															window.innerWidth <
															768
																? "15px"
																: "20px",
														lineHeight: "1.6",
														padding:
															window.innerWidth <
															768
																? "0 5px"
																: 0,
													}}>
													This isn't just a fragrance
													launch. It's a curated
													universe where you don't
													just wear the fragrance, you
													live it.
												</p>
												<div
													style={{
														margin:
															window.innerWidth <
															768
																? "20px 0"
																: "30px 0",
													}}>
													<p
														style={{
															fontSize:
																window.innerWidth <
																768
																	? "18px"
																	: "20px",
															fontWeight: "bold",
															marginBottom:
																"10px",
														}}>
														Thursday, 24 April 2025
													</p>
													<p
														style={{
															fontSize:
																window.innerWidth <
																768
																	? "16px"
																	: "18px",
														}}>
														18h30 for 19h00
													</p>
												</div>
												<div
													style={{
														margin:
															window.innerWidth <
															768
																? "20px 0"
																: "30px 0",
													}}>
													<p
														style={{
															fontSize:
																window.innerWidth <
																768
																	? "16px"
																	: "18px",
															marginBottom:
																"10px",
														}}>
														The Polo Room
													</p>
													<p
														style={{
															fontSize:
																window.innerWidth <
																768
																	? "16px"
																	: "18px",
															marginBottom:
																"10px",
														}}>
														Corner Forrest Road &
														6th Avenue
													</p>
													<p
														style={{
															fontSize:
																window.innerWidth <
																768
																	? "16px"
																	: "18px",
														}}>
														Inanda, Sandton,
														Johannesburg
													</p>
												</div>
												<p
													style={{
														fontSize:
															window.innerWidth <
															768
																? "18px"
																: "20px",
														fontWeight: "bold",
														margin:
															window.innerWidth <
															768
																? "15px 0"
																: "20px 0",
													}}>
													Dress Code: OTT Glam Pink
												</p>
												<div
													style={{
														margin:
															window.innerWidth <
															768
																? "20px 0"
																: "30px 0",
														fontSize:
															window.innerWidth <
															768
																? "14px"
																: "16px",
													}}>
													<p
														style={{
															marginBottom:
																"10px",
															padding:
																window.innerWidth <
																768
																	? "0 5px"
																	: 0,
														}}>
														Please Enjoy
														Responsibly. UBER return
														vouchers to the value of
														R250 will be provided.
													</p>
													<p
														style={{
															marginBottom:
																"10px",
														}}>
														Kindly RSVP by 21 March
														2025
													</p>
													<p
														style={{
															fontWeight: "bold",
														}}>
														#JuicyLife
													</p>
												</div>
											</motion.div>
										)}

										{/* RSVP NOW button - only visible after animation */}
										<AnimatePresence>
											{this.state.showInviteText && (
												<motion.button
													initial='hidden'
													animate='visible'
													variants={buttonVariants}
													onClick={this.openRsvpForm}
													style={{
														backgroundColor: "#000",
														padding: "15px 30px",
														position: "absolute",
														bottom: -20,
														borderRadius: 30,
														marginTop: -50, // Adjust position as needed
														border: "none",
														cursor: "pointer",
														boxShadow:
															"0px 2px 4px rgba(0, 0, 0, 0.2)",
													}}>
													<span
														style={{
															color: "#FFF",
															fontSize: 18,
															fontWeight: "bold",
															textAlign: "center",
														}}>
														RSVP NOW
													</span>
												</motion.button>
											)}
										</AnimatePresence>
									</motion.div>

									{/* Front card (animates up) */}
									<AnimatePresence>
										<motion.div
											initial='initial'
											animate={
												cardAnimating
													? "animate"
													: isBouncing
													? isBouncing // 'up' or 'down' based on state
													: "initial"
											}
											variants={frontCardVariants}
											style={{
												position: "absolute",
												zIndex: 5,
											}}>
											<div
												onClick={
													this.startCardAnimation
												}
												style={{ cursor: "pointer" }}>
												<img
													src={
														eventData.invitationCard ||
														defaultCardImg
													}
													alt='Invitation card'
													style={{
														height: "auto",
														width: Math.min(
															window.innerWidth *
																0.75,
															350,
														),
														maxWidth: 350,
														objectFit: "contain",
													}}
												/>
											</div>
										</motion.div>
									</AnimatePresence>
								</>
							) : (
								<img
									src={
										eventData.acceptedCard ||
										defaultAcceptedImg
									}
									alt='Accepted card'
									style={{
										height: "auto",
										width: Math.min(
											window.innerWidth * 0.75,
											350,
										),
										maxWidth: 350,
										objectFit: "contain",
									}}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}

	renderLoading = () => {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					width: "100vw",
					backgroundColor: "#FFF",
				}}>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#FFF",
						padding: 20,
						borderRadius: 10,
						boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
					}}>
					<div className='spinner'></div>
					<span
						style={{
							fontSize: 16,
							fontWeight: 600,
							color: "#000",
							marginTop: 10,
							fontFamily: "Gotham",
						}}>
						LOADING INVITATION
					</span>
				</div>
			</div>
		);
	};

	renderError = (
		message = "Something went wrong. Please try again later.",
	) => {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					width: "100vw",
					backgroundColor: "#FFF",
					padding: 20,
				}}>
				<span
					style={{
						fontSize: 18,
						fontWeight: 600,
						color: "#d32f2f",
						textAlign: "center",
						marginBottom: 20,
					}}>
					{message}
				</span>
				<button
					style={{
						backgroundColor: "#000",
						padding: "12px 24px",
						borderRadius: 4,
						border: "none",
						cursor: "pointer",
					}}
					onClick={this.fetchEventData}>
					<span style={{ color: "#FFF", fontWeight: 600 }}>
						Try Again
					</span>
				</button>
			</div>
		);
	};

	rsvpForm = () => {
		const { eventData } = this.state;

		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					backgroundColor: "#FFF",
					zIndex: 999,
				}}>
				<RsvpForm
					eventId={this.props.eventId}
					title={eventData?.title || "Event Invitation"}
					close={() => {
						this.setState({
							rsvpForm: !this.state.rsvpForm,
						});
						this.getLocalData();
					}}
				/>
			</div>
		);
	};
}
