import React, { useState } from "react";
import {
	RiMailSendLine,
	RiCheckLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";
import { api_dashboard } from "../Api";

const Domains = () => {
	const [testEmailAddress, setTestEmailAddress] = useState("");
	const [sending, setSending] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);

	const domains = [
		{
			id: "1",
			domain: "elveeconsultancy.co",
			email: "events@elveeconsultancy.co",
			status: "active",
			verified: true,
			lastUsed: "2024-03-20",
			dailyLimit: 2000,
			usedToday: 0,
			spfRecord: true,
			dkimRecord: true,
			dmarcRecord: true,
			createdAt: "2024-01-15",
		},
		// {
		// 	id: "2",
		// 	domain: "elveemarketing.com",
		// 	email: "newsletter@elveemarketing.com",
		// 	status: "active",
		// 	verified: true,
		// 	lastUsed: "2024-03-19",
		// 	dailyLimit: 5000,
		// 	usedToday: 2431,
		// 	spfRecord: true,
		// 	dkimRecord: true,
		// 	dmarcRecord: true,
		// 	createdAt: "2024-02-01",
		// },
		// {
		// 	id: "3",
		// 	domain: "elveeevents.net",
		// 	email: "hello@elveeevents.net",
		// 	status: "pending",
		// 	verified: false,
		// 	lastUsed: null,
		// 	dailyLimit: 1000,
		// 	usedToday: 0,
		// 	spfRecord: false,
		// 	dkimRecord: false,
		// 	dmarcRecord: false,
		// 	createdAt: "2024-03-18",
		// },
	];

	const handleSendTest = async () => {
		if (!testEmailAddress) return;

		setSending(true);
		try {
			// Implement actual API call here
			await new Promise((resolve) => setTimeout(resolve, 1500)); // Simulated delay
			setShowSuccess(true);
			setTimeout(() => setShowSuccess(false), 3000);
		} catch (error) {
			console.error("Error sending test email:", error);
		} finally {
			setSending(false);
		}
	};

	return (
		<div className='space-y-8'>
			<div className='flex justify-between items-center'>
				<h2 className='text-2xl font-semibold text-white'>
					Email Domains
				</h2>
				<button className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90'>
					Add Domain
				</button>
			</div>

			{/* Gmail Disclaimer */}
			<div className='bg-amber-900/20 border border-amber-500/50 rounded-lg p-6'>
				<div className='flex items-start space-x-3'>
					<RiInformationLine className='w-6 h-6 text-amber-500 flex-shrink-0 mt-1' />
					<div className='space-y-2'>
						<h3 className='text-lg font-medium text-amber-500'>
							Gmail Sending Limits
						</h3>
						<div className='text-amber-200/90 space-y-2'>
							<p>• Free Gmail Accounts: 500 emails per day</p>
							<p>
								• Google Workspace Accounts: 2,000 emails per
								day
							</p>
							<p>• Bulk sending (5,000+ emails) requires:</p>
							<ul className='list-disc list-inside pl-4 text-sm'>
								<li>Strong authentication (SPF, DKIM)</li>
								<li>One-click unsubscribe functionality</li>
								<li>Compliance with bulk sender guidelines</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			{/* Domains List */}
			<div className='bg-dark rounded-lg shadow-xl'>
				<div className='p-6'>
					<h3 className='text-xl font-medium text-white mb-6'>
						Active Domains
					</h3>
					{domains.map((domain, index) => (
						<div
							key={index}
							className='bg-gray-800/50 rounded-lg p-6 space-y-4'>
							<div className='flex justify-between items-start'>
								<div>
									<div className='flex items-center space-x-2'>
										<h4 className='text-lg font-medium text-white'>
											{domain.domain}
										</h4>
										{domain.verified && (
											<span className='bg-green-500/20 text-green-400 text-xs px-2 py-1 rounded-full flex items-center'>
												<RiCheckLine className='w-3 h-3 mr-1' />
												Verified
											</span>
										)}
									</div>
									<p className='text-gray-400 mt-1'>
										{domain.email}
									</p>
								</div>
								<div className='text-right'>
									<p className='text-sm text-gray-400'>
										Daily Usage
									</p>
									<div className='flex items-center space-x-2 mt-1'>
										<div className='w-32 h-2 bg-gray-700 rounded-full'>
											<div
												className='h-full bg-primary rounded-full'
												style={{
													width: `${
														(domain.usedToday /
															domain.dailyLimit) *
														100
													}%`,
												}}
											/>
										</div>
										<span className='text-sm text-gray-400'>
											{domain.usedToday}/
											{domain.dailyLimit}
										</span>
									</div>
								</div>
							</div>

							{/* Test Email Section */}
							<div className='mt-4 border-t border-gray-700 pt-4'>
								<div className='flex items-center space-x-4'>
									<input
										type='email'
										placeholder='Enter test email address'
										value={testEmailAddress}
										onChange={(e) =>
											setTestEmailAddress(e.target.value)
										}
										className='flex-1 rounded-md bg-gray-700 border-gray-600 text-white'
									/>
									<button
										onClick={handleSendTest}
										disabled={sending}
										className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 flex items-center space-x-2'>
										<RiMailSendLine className='w-5 h-5' />
										<span>
											{sending
												? "Sending..."
												: "Send Test Email"}
										</span>
									</button>
								</div>
								{showSuccess && (
									<p className='text-green-400 text-sm mt-2'>
										Test email sent successfully!
									</p>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Domains;
