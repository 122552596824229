import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Font from "react-font";
import Lottie from "react-lottie";
import traq_logo from "./Dashboard/Unauthed/traq_app_logo.svg";
import * as animationData from "./Unauthed/animation_ln08boza.json";

const fadeIn = {
	initial: { opacity: 0, y: 20 },
	animate: { opacity: 1, y: 0 },
	transition: { duration: 0.6 },
};

const stagger = {
	animate: {
		transition: {
			staggerChildren: 0.2,
		},
	},
};

const LandingPage = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	return (
		<div className='min-h-screen bg-[#1F2229] text-white'>
			{/* Navigation */}
			<nav className='fixed w-full bg-[#121419]/80 backdrop-blur-lg z-50 border-b border-[#2A2F3C]'>
				<div className='container mx-auto px-6 py-4 flex justify-between items-center'>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5 }}
						className='flex items-center space-x-2'>
						<img
							src={traq_logo}
							alt='TRAQ'
							className='h-10 w-auto'
						/>
						<span className='text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent'>
							<Font family='Poppins' weight={800}>
								RSVP - NOW!
							</Font>
						</span>
					</motion.div>
					<div className='hidden md:flex space-x-8 items-center'>
						<a
							href='#features'
							className='text-gray-300 hover:text-blue-400 transition-colors duration-300'>
							Features
						</a>
						<a
							href='#how-it-works'
							className='text-gray-300 hover:text-blue-400 transition-colors duration-300'>
							How it Works
						</a>
						<a
							href='#contact'
							className='text-gray-300 hover:text-blue-400 transition-colors duration-300'>
							Contact
						</a>
						<Link
							to='/login'
							className='px-6 py-2 bg-[#0068FF] text-white rounded-xl hover:bg-blue-600 
							transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-blue-500/25'>
							Login
						</Link>
					</div>
				</div>
			</nav>

			{/* Hero Section */}
			<motion.section
				initial='initial'
				animate='animate'
				variants={stagger}
				className='min-h-[90vh] flex items-center justify-center relative overflow-hidden'>
				{/* Background gradient effect */}
				<div className='absolute inset-0 bg-gradient-to-br from-blue-500/10 via-purple-500/5 to-transparent'></div>
				<div className='absolute top-1/4 right-0 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl'></div>
				<div className='absolute bottom-1/4 left-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl'></div>

				<div className='container mx-auto px-4 flex flex-col md:flex-row items-center justify-between relative z-10 gap-12'>
					{/* Left side content */}
					<motion.div
						variants={fadeIn}
						className='w-full md:w-1/2 text-center md:text-left'>
						<div className='relative inline-block'>
							<motion.div
								initial={{ width: 0 }}
								animate={{ width: "100%" }}
								transition={{ duration: 1, delay: 0.5 }}
								className='absolute -top-4 left-0 h-1 bg-gradient-to-r from-blue-500 to-purple-500'
							/>
							<motion.span
								variants={fadeIn}
								className='text-blue-400 font-semibold mb-4 block'>
								<Font family='Poppins' weight={600}>
									Welcome to TRAQ - RSVP
								</Font>
							</motion.span>
						</div>
						<motion.h1
							variants={fadeIn}
							className='text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-white via-white to-gray-400 bg-clip-text text-transparent leading-tight'>
							<Font family='Poppins' weight={800}>
								Professional
								<br />
								Event Management
							</Font>
						</motion.h1>
						<motion.p
							variants={fadeIn}
							className='text-xl text-gray-400 mb-8 max-w-2xl mx-auto md:mx-0 leading-relaxed'>
							<Font family='Poppins' weight={400}>
								Create beautiful event pages, manage RSVPs, and
								track attendance with our powerful platform.
								Perfect for any event, from weddings to
								conferences.
							</Font>
						</motion.p>
						<motion.div
							variants={fadeIn}
							className='flex flex-col sm:flex-row gap-4 justify-center md:justify-start'>
							<Link
								to='/dashboard'
								className='group px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl 
								transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-blue-500/25 
								text-lg font-semibold relative overflow-hidden'>
								<span className='relative z-10'>
									Get Started Free
								</span>
								<div className='absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 transition-transform duration-300 transform translate-x-full group-hover:translate-x-0'></div>
							</Link>
							<a
								href='#features'
								className='px-8 py-4 bg-[#2A2F3C] text-gray-300 rounded-xl hover:bg-[#353B4B] 
								transition-all duration-300 transform hover:scale-105 hover:text-white 
								text-lg font-semibold border border-gray-700 hover:border-blue-500/50'>
								Learn More
							</a>
						</motion.div>

						{/* Stats Section */}
						<motion.div
							variants={fadeIn}
							className='mt-12 grid grid-cols-3 gap-6 max-w-xl mx-auto md:mx-0'>
							{[
								{ number: "1000+", label: "Events Created" },
								{ number: "50k+", label: "RSVPs Managed" },
								{ number: "99%", label: "Satisfaction" },
							].map((stat, index) => (
								<div key={index} className='text-center'>
									<div className='text-6xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent'>
										{stat.number}
									</div>
									<div className='text-sm text-gray-400'>
										{stat.label}
									</div>
								</div>
							))}
						</motion.div>
					</motion.div>

					{/* Right side with Lottie */}
					<div className='hidden md:block w-1/2 relative'>
						<motion.div
							initial={{ opacity: 0, scale: 0.8 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.8 }}
							className='flex items-center justify-center'>
							<Lottie
								options={defaultOptions}
								height={550}
								width={650}
							/>
						</motion.div>
					</div>
				</div>
			</motion.section>

			{/* Phone Showcase Section */}
			<section className='py-20 bg-[#121419] overflow-hidden'>
				<div className='container mx-auto px-4'>
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='text-center mb-16'>
						<h2 className='text-3xl md:text-4xl font-bold mb-4'>
							<Font family='Poppins' weight={800}>
								Beautiful Event Invites
							</Font>
						</h2>
						<p className='text-xl text-gray-400'>
							<Font family='Poppins' weight={400}>
								Create stunning RSVP pages that wow your guests
							</Font>
						</p>
					</motion.div>

					{/* Phone Showcase */}
					<motion.div
						initial={{ x: "100%" }}
						whileInView={{ x: "0%" }}
						transition={{
							duration: 1.2,
							ease: "easeOut",
						}}
						viewport={{ once: true }}
						className='flex justify-center items-center gap-8 py-12 perspective-[2000px]'>
						{[1, 2, 3].map((index) => (
							<motion.div
								key={index}
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{
									duration: 0.6,
									delay: index * 0.2,
								}}
								viewport={{ once: true }}
								className='flex-none w-[280px] h-[560px] bg-[#2A2F3C] rounded-[3rem] p-4 
									shadow-xl group hover:z-50 relative preserve-3d
									transition-all duration-500 ease-out'>
								{/* iPhone Notch */}
								<div className='absolute top-4 left-1/2 -translate-x-1/2 w-[120px] h-[25px] bg-black rounded-full z-10'></div>
								<div
									className='w-full h-full rounded-[2.5rem] overflow-hidden bg-gradient-to-br from-blue-500 to-purple-600 relative
									[transform:rotateY(-20deg)_rotateX(10deg)] group-hover:[transform:rotateY(0deg)_rotateX(0deg)] group-hover:scale-105
									transition-transform duration-500 ease-out will-change-transform
									shadow-lg group-hover:shadow-2xl group-hover:shadow-blue-500/30
									origin-center'>
									{/* Mock RSVP Design Content */}
									<div className='absolute inset-0'>
										{/* Status Bar */}
										<div className='h-12 bg-black/20 backdrop-blur-md flex items-center justify-between px-6'>
											<span className='text-white text-xs'>
												9:41
											</span>
											<div className='flex items-center space-x-2'>
												<svg
													className='w-4 h-4 text-white'
													fill='currentColor'
													viewBox='0 0 24 24'>
													<path d='M12 21a9 9 0 100-18 9 9 0 000 18z'></path>
												</svg>
												<svg
													className='w-4 h-4 text-white'
													fill='currentColor'
													viewBox='0 0 24 24'>
													<path d='M2 9.5V4a1 1 0 011-1h18a1 1 0 011 1v5.5'></path>
												</svg>
											</div>
										</div>
										<div className='p-6'>
											<div className='bg-white/10 backdrop-blur-md rounded-2xl p-6 h-[460px] overflow-hidden'>
												{index === 1 ? (
													<div className='text-white space-y-4'>
														<div className='text-center'>
															<h3 className='text-2xl font-bold mb-2'>
																Sarah & Michael
															</h3>
															<p className='text-sm text-white/80'>
																Request the
																pleasure of your
																company
															</p>
														</div>
														<div className='my-8 text-center'>
															<div className='text-4xl font-serif mb-2'>
																♥
															</div>
															<p className='text-lg'>
																Saturday,
																December 31st
															</p>
															<p className='text-sm text-white/80'>
																at 4:00 PM
															</p>
														</div>
														<div className='text-center text-sm text-white/80'>
															<p>
																The Grand Plaza
																Hotel
															</p>
															<p>
																123 Elegant
																Avenue
															</p>
															<p>
																New York, NY
																10001
															</p>
														</div>
														<button className='w-full mt-8 bg-white/20 hover:bg-white/30 text-white py-3 rounded-xl transition-colors'>
															RSVP Now
														</button>
													</div>
												) : index === 2 ? (
													<div className='text-white space-y-4'>
														<div className='text-center'>
															<h3 className='text-2xl font-bold mb-2'>
																Annual Tech
																Summit
															</h3>
															<p className='text-sm text-white/80'>
																2024 Innovation
																Conference
															</p>
														</div>
														<div className='my-6 space-y-4'>
															<div className='bg-white/5 p-4 rounded-xl'>
																<p className='text-sm font-semibold'>
																	Keynote
																	Speakers
																</p>
																<p className='text-xs text-white/70'>
																	Industry
																	Leaders &
																	Innovators
																</p>
															</div>
															<div className='bg-white/5 p-4 rounded-xl'>
																<p className='text-sm'>
																	March 15-17,
																	2024
																</p>
																<p className='text-xs text-white/70'>
																	Tech Center,
																	Silicon
																	Valley
																</p>
															</div>
														</div>
														<button className='w-full bg-white/20 hover:bg-white/30 text-white py-3 rounded-xl transition-colors'>
															Register Now
														</button>
													</div>
												) : (
													<div className='text-white space-y-4'>
														<div className='text-center'>
															<h3 className='text-2xl font-bold mb-2'>
																Emma's Sweet 16
															</h3>
															<p className='text-sm text-white/80'>
																Join us for a
																magical evening
															</p>
														</div>
														<div className='my-6 text-center'>
															<div className='text-4xl mb-4'>
																🎉
															</div>
															<p className='text-lg'>
																Friday, July
																12th
															</p>
															<p className='text-sm text-white/80'>
																7:00 PM - 11:00
																PM
															</p>
														</div>
														<div className='bg-white/5 p-4 rounded-xl text-center'>
															<p className='text-sm'>
																Garden Party
																Theme
															</p>
															<p className='text-xs text-white/70'>
																Dress Code:
																Semi-Formal
															</p>
														</div>
														<button className='w-full bg-white/20 hover:bg-white/30 text-white py-3 rounded-xl transition-colors'>
															Confirm Attendance
														</button>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								{/* iPhone Home Indicator */}
								<div className='absolute bottom-6 left-1/2 -translate-x-1/2 w-[120px] h-1 bg-white/20 rounded-full'></div>
							</motion.div>
						))}
					</motion.div>
				</div>
			</section>

			{/* Dashboard Showcase Section */}
			<section className='py-20 bg-[#1F2229] relative overflow-hidden'>
				{/* Background Elements */}
				<div className='absolute inset-0'>
					<div className='absolute top-1/3 left-0 w-96 h-96 bg-blue-500/5 rounded-full filter blur-3xl'></div>
					<div className='absolute bottom-1/3 right-0 w-96 h-96 bg-purple-500/5 rounded-full filter blur-3xl'></div>
				</div>

				<div className='container mx-auto px-4 relative z-10'>
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='text-center mb-16'>
						<span className='text-blue-400 font-semibold mb-4 block'>
							<Font family='Poppins' weight={600}>
								Powerful Dashboard
							</Font>
						</span>
						<h2 className='text-4xl md:text-5xl font-bold mb-6'>
							<Font family='Poppins' weight={800}>
								Everything You Need in One Place
							</Font>
						</h2>
						<p className='text-xl text-gray-400 max-w-3xl mx-auto'>
							<Font family='Poppins' weight={400}>
								Track RSVPs, manage guest lists, and get
								real-time analytics with our intuitive dashboard
							</Font>
						</p>
					</motion.div>

					<div className='relative'>
						{/* Laptop Frame */}
						<motion.div
							initial={{ opacity: 0, y: 50 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.8 }}
							viewport={{ amount: 0.3 }}
							className='relative mx-auto max-w-5xl'>
							{/* Laptop Body */}
							<div className='relative bg-[#2A2F3C] rounded-2xl p-8 shadow-2xl'>
								{/* Screen Content */}
								<div className='relative bg-[#1F2229] rounded-lg p-6 border border-[#353B4B]'>
									{/* Dashboard Header */}
									<div className='flex justify-between items-center mb-6'>
										<div className='flex items-center space-x-4'>
											<motion.div
												initial={{
													scale: 0.8,
													opacity: 0,
												}}
												whileInView={{
													scale: 1,
													opacity: 1,
												}}
												transition={{ duration: 0.5 }}
												viewport={{ amount: 0.3 }}
												className='w-8 h-8 bg-blue-500 rounded-lg'
											/>
											<motion.div
												initial={{ x: -20, opacity: 0 }}
												whileInView={{
													x: 0,
													opacity: 1,
												}}
												transition={{
													duration: 0.5,
													delay: 0.2,
												}}
												viewport={{ amount: 0.3 }}
												className='text-white text-lg font-semibold'>
												Dashboard Overview
											</motion.div>
										</div>
										<div className='flex space-x-2'>
											<motion.div
												initial={{ scale: 0 }}
												whileInView={{ scale: 1 }}
												transition={{
													duration: 0.3,
													delay: 0.4,
												}}
												viewport={{ amount: 0.3 }}
												className='w-3 h-3 bg-red-500 rounded-full'
											/>
											<motion.div
												initial={{ scale: 0 }}
												whileInView={{ scale: 1 }}
												transition={{
													duration: 0.3,
													delay: 0.5,
												}}
												viewport={{ amount: 0.3 }}
												className='w-3 h-3 bg-yellow-500 rounded-full'
											/>
											<motion.div
												initial={{ scale: 0 }}
												whileInView={{ scale: 1 }}
												transition={{
													duration: 0.3,
													delay: 0.6,
												}}
												viewport={{ amount: 0.3 }}
												className='w-3 h-3 bg-green-500 rounded-full'
											/>
										</div>
									</div>

									{/* Dashboard Grid */}
									<div className='grid grid-cols-3 gap-6 mb-8'>
										{/* Stats Cards */}
										{[
											{
												label: "Total RSVPs",
												value: "1,234",
												color: "blue",
											},
											{
												label: "Confirmed",
												value: "856",
												color: "green",
											},
											{
												label: "Pending",
												value: "378",
												color: "yellow",
											},
										].map((stat, index) => (
											<motion.div
												key={index}
												initial={{ opacity: 0, y: 20 }}
												whileInView={{
													opacity: 1,
													y: 0,
												}}
												transition={{
													duration: 0.6,
													delay: index * 0.1,
												}}
												viewport={{ amount: 0.3 }}
												className='bg-[#2A2F3C] p-4 rounded-lg border border-[#353B4B]'>
												<div className='text-gray-400 text-sm mb-2'>
													{stat.label}
												</div>
												<motion.div
													initial={{
														scale: 0.5,
														opacity: 0,
													}}
													whileInView={{
														scale: 1,
														opacity: 1,
													}}
													transition={{
														duration: 0.5,
														delay:
															index * 0.1 + 0.3,
													}}
													viewport={{ amount: 0.3 }}
													className={`text-2xl font-bold text-${stat.color}-400`}>
													{stat.value}
												</motion.div>
											</motion.div>
										))}
									</div>

									{/* Chart Area */}
									<div className='grid grid-cols-2 gap-6'>
										<div className='bg-[#2A2F3C] p-4 rounded-lg border border-[#353B4B] h-48'>
											{/* Bar Chart Mockup */}
											<div className='flex items-end justify-between h-32 mt-4'>
												{[
													40, 70, 55, 80, 60, 90, 75,
												].map((height, index) => (
													<motion.div
														key={index}
														initial={{
															height: 0,
															opacity: 0,
														}}
														whileInView={{
															height: `${height}%`,
															opacity: 1,
														}}
														transition={{
															duration: 0.8,
															delay: index * 0.1,
															ease: "easeOut",
														}}
														viewport={{
															amount: 0.3,
														}}
														className='w-8 bg-gradient-to-t from-blue-500 to-purple-500 rounded-t-lg'
													/>
												))}
											</div>
										</div>
										<div className='bg-[#2A2F3C] p-4 rounded-lg border border-[#353B4B] h-48'>
											{/* Line Chart Mockup */}
											<motion.svg
												className='w-full h-32 mt-4'
												viewBox='0 0 200 100'>
												<motion.path
													d='M0 80 Q 40 40, 80 70 T 160 30'
													fill='none'
													stroke='url(#gradient)'
													strokeWidth='3'
													initial={{
														pathLength: 0,
														opacity: 0,
													}}
													whileInView={{
														pathLength: 1,
														opacity: 1,
													}}
													transition={{
														duration: 1.5,
														ease: "easeInOut",
													}}
													viewport={{ amount: 0.3 }}
												/>
												<defs>
													<linearGradient
														id='gradient'
														x1='0%'
														y1='0%'
														x2='100%'
														y2='0%'>
														<stop
															offset='0%'
															stopColor='#3B82F6'
														/>
														<stop
															offset='100%'
															stopColor='#8B5CF6'
														/>
													</linearGradient>
												</defs>
											</motion.svg>
										</div>
									</div>
								</div>

								{/* Laptop Base */}
								<div className='absolute -bottom-6 left-1/2 transform -translate-x-1/2 w-1/3 h-2 bg-[#353B4B] rounded-b-xl'></div>
							</div>
						</motion.div>

						{/* Floating Elements */}
						<motion.div
							initial={{ opacity: 0, scale: 0.8 }}
							whileInView={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.8, delay: 0.3 }}
							viewport={{ once: true }}
							className='absolute -top-12 -left-12 w-40 h-40 bg-gradient-to-br from-purple-500/10 to-blue-500/10 rounded-2xl backdrop-blur-xl border border-white/10 p-4 transform rotate-12'>
							<div className='w-full h-full rounded-lg bg-gradient-to-br from-purple-500 to-blue-500 opacity-20'></div>
						</motion.div>

						<motion.div
							initial={{ opacity: 0, scale: 0.8 }}
							whileInView={{ opacity: 1, scale: 1 }}
							transition={{ duration: 0.8, delay: 0.4 }}
							viewport={{ once: true }}
							className='absolute -bottom-8 -right-8 w-48 h-48 bg-gradient-to-br from-blue-500/10 to-purple-500/10 rounded-2xl backdrop-blur-xl border border-white/10 p-4 transform -rotate-12'>
							<div className='w-full h-full rounded-lg bg-gradient-to-br from-blue-500 to-purple-500 opacity-20'></div>
						</motion.div>
					</div>
				</div>
			</section>

			{/* Features Section */}
			<section
				id='features'
				className='py-20 bg-[#121419] relative overflow-hidden'>
				{/* Background Elements */}
				<div className='absolute inset-0'>
					<div className='absolute top-0 left-0 w-96 h-96 bg-blue-500/5 rounded-full filter blur-3xl'></div>
					<div className='absolute bottom-0 right-0 w-96 h-96 bg-purple-500/5 rounded-full filter blur-3xl'></div>
				</div>

				<div className='container mx-auto px-4 relative z-10'>
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='text-center mb-16'>
						<span className='text-blue-400 font-semibold mb-4 block'>
							<Font family='Poppins' weight={600}>
								Why Choose TRAQ - RSVP
							</Font>
						</span>
						<h2 className='text-4xl md:text-5xl font-bold mb-6'>
							<Font family='Poppins' weight={800}>
								Powerful Features for Modern Events
							</Font>
						</h2>
						<p className='text-xl text-gray-400 max-w-3xl mx-auto'>
							<Font family='Poppins' weight={400}>
								Everything you need to create, manage, and host
								successful events in one platform
							</Font>
						</p>
					</motion.div>

					<div className='grid md:grid-cols-2 lg:grid-cols-3 gap-8'>
						{[
							{
								title: "Beautiful Event Pages",
								description:
									"Create stunning, mobile-responsive event pages with customizable themes, layouts, and branding options",
								icon: "✨",
								features: [
									"Custom domains",
									"Theme customization",
									"Mobile-first design",
								],
							},
							{
								title: "Smart RSVP Management",
								description:
									"Efficiently handle guest responses with automated confirmations, reminders, and guest list management",
								icon: "📝",
								features: [
									"Auto-reminders",
									"Plus-one management",
									"Dietary preferences",
								],
							},
							{
								title: "Real-time Analytics",
								description:
									"Track event performance with detailed insights on attendance, engagement, and guest behavior",
								icon: "📊",
								features: [
									"Response tracking",
									"Engagement metrics",
									"Export reports",
								],
							},
							{
								title: "Guest Communication",
								description:
									"Keep your guests informed with automated emails, SMS updates, and event notifications",
								icon: "💌",
								features: [
									"Email campaigns",
									"SMS notifications",
									"Custom templates",
								],
							},
							{
								title: "Seating Management",
								description:
									"Organize your event seating with interactive table planning and guest assignments",
								icon: "🪑",
								features: [
									"Table planning",
									"Group seating",
									"Capacity management",
								],
							},
							{
								title: "Event Check-in",
								description:
									"Streamline the check-in process with QR codes, digital tickets, and attendance tracking",
								icon: "✅",
								features: [
									"QR code check-in",
									"Digital tickets",
									"Guest verification",
								],
							},
						].map((feature, index) => (
							<motion.div
								key={index}
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{
									duration: 0.6,
									delay: index * 0.1,
								}}
								viewport={{ once: true }}
								className='bg-gradient-to-br from-[#1F2229] to-[#1A1D24] p-8 rounded-2xl border border-[#2A2F3C] 
								hover:border-blue-500/50 group transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/10'>
								<div className='flex items-center space-x-4 mb-6'>
									<div
										className='text-4xl bg-gradient-to-br from-blue-500/10 to-purple-500/10 p-3 rounded-xl
									group-hover:scale-110 transition-transform duration-300'>
										{feature.icon}
									</div>
									<h3 className='text-xl font-bold'>
										<Font family='Poppins' weight={600}>
											{feature.title}
										</Font>
									</h3>
								</div>
								<p className='text-gray-400 mb-6'>
									<Font family='Poppins' weight={400}>
										{feature.description}
									</Font>
								</p>
								<ul className='space-y-2'>
									{feature.features.map((item, i) => (
										<li
											key={i}
											className='flex items-center text-gray-300'>
											<svg
												className='w-5 h-5 text-blue-500 mr-2'
												fill='none'
												stroke='currentColor'
												viewBox='0 0 24 24'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M5 13l4 4L19 7'></path>
											</svg>
											<span className='text-sm'>
												<Font
													family='Poppins'
													weight={400}>
													{item}
												</Font>
											</span>
										</li>
									))}
								</ul>
							</motion.div>
						))}
					</div>

					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='mt-16 text-center'>
						<Link
							to='/dashboard'
							className='inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-600 to-blue-500 text-white rounded-xl 
							transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-blue-500/25 
							text-lg font-semibold group'>
							<span>Start Creating Your Event</span>
							<svg
								className='w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M17 8l4 4m0 0l-4 4m4-4H3'></path>
							</svg>
						</Link>
					</motion.div>
				</div>
			</section>

			{/* How it Works */}
			<section
				id='how-it-works'
				className='py-20 bg-[#1F2229] relative overflow-hidden'>
				{/* Background Elements */}
				<div className='absolute inset-0'>
					<div className='absolute top-1/4 left-0 w-96 h-96 bg-blue-500/10 rounded-full filter blur-3xl'></div>
					<div className='absolute bottom-1/4 right-0 w-96 h-96 bg-purple-500/10 rounded-full filter blur-3xl'></div>
					<div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-px bg-gradient-to-r from-transparent via-blue-500/20 to-transparent'></div>
				</div>

				<div className='container mx-auto px-4 relative z-10'>
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='text-center mb-16'>
						<span className='text-blue-400 font-semibold mb-4 block'>
							<Font family='Poppins' weight={600}>
								Simple Process
							</Font>
						</span>
						<h2 className='text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-white via-white to-gray-400 bg-clip-text text-transparent'>
							<Font family='Poppins' weight={800}>
								How It Works
							</Font>
						</h2>
						<p className='text-xl text-gray-400 max-w-3xl mx-auto'>
							<Font family='Poppins' weight={400}>
								Create and manage your events in three simple
								steps. Our intuitive platform makes it easy to
								get started.
							</Font>
						</p>
					</motion.div>

					<div className='grid md:grid-cols-3 gap-12 relative'>
						{/* Connection Lines */}
						<div className='hidden md:block absolute top-1/2 left-0 w-full h-1 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 -translate-y-1/2'></div>

						{[
							{
								step: "1",
								title: "Create Your Event",
								description:
									"Design your perfect event page with our intuitive builder. Add custom themes, images, and details that reflect your style.",
								icon: "✨",
								color: "blue",
								features: [
									"Custom themes",
									"Rich media support",
									"Mobile responsive",
								],
							},
							{
								step: "2",
								title: "Share & Invite",
								description:
									"Share your event page through multiple channels. Send personalized invitations via email or social media.",
								icon: "🔗",
								color: "purple",
								features: [
									"Multiple sharing options",
									"Custom domains",
									"Social media integration",
								],
							},
							{
								step: "3",
								title: "Manage RSVPs",
								description:
									"Track responses in real-time. Get insights on attendance and manage guest communications effortlessly.",
								icon: "📊",
								color: "blue",
								features: [
									"Real-time tracking",
									"Guest messaging",
									"Analytics dashboard",
								],
							},
						].map((step, index) => (
							<motion.div
								key={index}
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{
									duration: 0.6,
									delay: index * 0.2,
								}}
								viewport={{ once: true }}
								className='relative group'>
								{/* Step Number */}
								<div className='absolute -top-4 left-1/2 -translate-x-1/2 w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white font-bold text-sm ring-4 ring-[#1F2229] z-10'>
									{step.step}
								</div>

								{/* Content Card */}
								<div
									className='bg-gradient-to-br from-[#2A2F3C] to-[#1A1D24] rounded-2xl p-8 
									border border-[#353B4B] group-hover:border-blue-500/50 
									transition-all duration-500 transform hover:-translate-y-2 
									hover:shadow-xl hover:shadow-blue-500/10'>
									{/* Icon */}
									<div className='text-4xl mb-6 transform group-hover:scale-110 transition-transform duration-300'>
										{step.icon}
									</div>

									{/* Title */}
									<h3 className='text-2xl font-bold mb-4 bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent'>
										<Font family='Poppins' weight={600}>
											{step.title}
										</Font>
									</h3>

									{/* Description */}
									<p className='text-gray-400 mb-6'>
										<Font family='Poppins' weight={400}>
											{step.description}
										</Font>
									</p>

									{/* Features */}
									<ul className='space-y-3'>
										{step.features.map((feature, i) => (
											<li
												key={i}
												className='flex items-center text-sm text-gray-300'>
												<motion.div
													initial={{ scale: 0 }}
													whileInView={{ scale: 1 }}
													transition={{
														duration: 0.3,
														delay: i * 0.1,
													}}
													className='w-2 h-2 rounded-full bg-blue-500 mr-2'
												/>
												{feature}
											</li>
										))}
									</ul>
								</div>
							</motion.div>
						))}
					</div>

					{/* CTA Button */}
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='mt-16 text-center'>
						<Link
							to='/dashboard'
							className='inline-flex items-center px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl 
							transition-all duration-300 transform hover:scale-105 hover:shadow-lg hover:shadow-blue-500/25 
							text-lg font-semibold group'>
							<span>Start Creating Your Event</span>
							<svg
								className='w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform'
								fill='none'
								stroke='currentColor'
								viewBox='0 0 24 24'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M17 8l4 4m0 0l-4 4m4-4H3'></path>
							</svg>
						</Link>
					</motion.div>
				</div>
			</section>

			{/* Pricing Section */}
			<section className='py-20 bg-[#1F2229] relative overflow-hidden'>
				{/* Background Elements */}
				<div className='absolute inset-0'>
					<div className='absolute top-0 left-0 w-96 h-96 bg-blue-500/5 rounded-full filter blur-3xl'></div>
					<div className='absolute bottom-0 right-0 w-96 h-96 bg-purple-500/5 rounded-full filter blur-3xl'></div>
				</div>

				<div className='container mx-auto px-4 relative z-10'>
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='text-center mb-16'>
						<span className='text-blue-400 font-semibold mb-4 block'>
							<Font family='Poppins' weight={600}>
								Pricing Plans
							</Font>
						</span>
						<h2 className='text-4xl md:text-5xl font-bold mb-6'>
							<Font family='Poppins' weight={800}>
								Choose Your Perfect Plan
							</Font>
						</h2>
						<p className='text-xl text-gray-400 max-w-3xl mx-auto'>
							<Font family='Poppins' weight={400}>
								Simple, transparent pricing that grows with you
							</Font>
						</p>
					</motion.div>

					<div className='grid md:grid-cols-3 gap-8 max-w-7xl mx-auto'>
						{/* Starter Plan */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.6, delay: 0.1 }}
							viewport={{ once: true }}
							className='bg-gradient-to-br from-[#1F2229] to-[#1A1D24] rounded-2xl border border-[#2A2F3C] 
							p-8 hover:border-blue-500/50 group transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/10
							transform hover:-translate-y-2'>
							<h3 className='text-2xl font-bold mb-4'>
								<Font family='Poppins' weight={600}>
									Starter
								</Font>
							</h3>
							<div className='mb-6'>
								<span className='text-4xl font-bold'>R599</span>
								<span className='text-gray-400'>/month</span>
								<p className='text-sm text-blue-400 mt-2'>
									40% off if billed annually
								</p>
							</div>
							<ul className='space-y-4 mb-8'>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Up to 2,000 RSVPs
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									1-5 Events
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Basic Analytics
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Email Support
								</li>
							</ul>
							<Link
								to='/dashboard'
								className='block w-full px-6 py-3 text-center bg-[#2A2F3C] text-white rounded-xl 
								hover:bg-blue-600 transition-all duration-300 font-semibold'>
								Get Started
							</Link>
						</motion.div>

						{/* Professional Plan */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.6, delay: 0.2 }}
							viewport={{ once: true }}
							className='bg-gradient-to-br from-[#1F2229] to-[#1A1D24] rounded-2xl border border-blue-500 
							p-8 hover:border-blue-400 group transition-all duration-300 hover:shadow-lg hover:shadow-blue-500/20
							transform hover:-translate-y-2 relative'>
							<div className='absolute -top-4 left-1/2 transform -translate-x-1/2'>
								<span className='bg-blue-500 text-white px-3 py-1 rounded-full text-sm'>
									Most Popular
								</span>
							</div>
							<h3 className='text-2xl font-bold mb-4'>
								<Font family='Poppins' weight={600}>
									Professional
								</Font>
							</h3>
							<div className='mb-6'>
								<span className='text-4xl font-bold'>
									R1,499
								</span>
								<span className='text-gray-400'>/month</span>
								<p className='text-sm text-blue-400 mt-2'>
									40% off if billed annually
								</p>
							</div>
							<ul className='space-y-4 mb-8'>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Up to 10,000 RSVPs
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Up to 20 Events
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Advanced Analytics
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Priority Support
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-blue-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Custom Branding
								</li>
							</ul>
							<Link
								to='/dashboard'
								className='block w-full px-6 py-3 text-center bg-blue-500 text-white rounded-xl 
								hover:bg-blue-600 transition-all duration-300 font-semibold'>
								Get Started
							</Link>
						</motion.div>

						{/* Agency Plan */}
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.6, delay: 0.3 }}
							viewport={{ once: true }}
							className='bg-gradient-to-br from-[#1F2229] to-[#1A1D24] rounded-2xl border border-[#2A2F3C] 
							p-8 hover:border-purple-500/50 group transition-all duration-300 hover:shadow-lg hover:shadow-purple-500/10
							transform hover:-translate-y-2'>
							<h3 className='text-2xl font-bold mb-4'>
								<Font family='Poppins' weight={600}>
									Agency
								</Font>
							</h3>
							<div className='mb-6'>
								<span className='text-4xl font-bold'>
									Custom
								</span>
								<p className='text-sm text-purple-400 mt-2'>
									Contact sales for pricing
								</p>
							</div>
							<ul className='space-y-4 mb-8'>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-purple-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Unlimited RSVPs
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-purple-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Unlimited Events
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-purple-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Custom Domain
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-purple-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									Dedicated Account Manager
								</li>
								<li className='flex items-center text-gray-300'>
									<svg
										className='w-5 h-5 text-purple-500 mr-2'
										fill='none'
										stroke='currentColor'
										viewBox='0 0 24 24'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											strokeWidth='2'
											d='M5 13l4 4L19 7'></path>
									</svg>
									API Access
								</li>
							</ul>
							<Link
								to='/contact'
								className='block w-full px-6 py-3 text-center bg-[#2A2F3C] text-white rounded-xl 
								hover:bg-purple-600 transition-all duration-300 font-semibold'>
								Contact Sales
							</Link>
						</motion.div>
					</div>
				</div>
			</section>

			{/* Contact Section */}
			<section id='contact' className='py-20 bg-[#121419]'>
				<div className='container mx-auto px-4'>
					<motion.div
						initial={{ opacity: 0, y: 20 }}
						whileInView={{ opacity: 1, y: 0 }}
						transition={{ duration: 0.6 }}
						viewport={{ once: true }}
						className='max-w-2xl mx-auto text-center'>
						<h2 className='text-3xl md:text-4xl font-bold mb-4'>
							<Font family='Poppins' weight={800}>
								Get in Touch
							</Font>
						</h2>
						<p className='text-xl text-gray-400 mb-8'>
							<Font family='Poppins' weight={400}>
								Have questions? We're here to help!
							</Font>
						</p>

						<form className='space-y-4'>
							<input
								type='email'
								placeholder='Your email'
								className='w-full px-4 py-3 rounded-xl bg-[#1F2229] border border-[#2A2F3C] 
								text-white placeholder-gray-500 focus:outline-none focus:border-blue-500 
								transition-all duration-300'
							/>
							<textarea
								placeholder='Your message'
								rows='4'
								className='w-full px-4 py-3 rounded-xl bg-[#1F2229] border border-[#2A2F3C] 
								text-white placeholder-gray-500 focus:outline-none focus:border-blue-500 
								transition-all duration-300'></textarea>
							<button
								type='submit'
								className='w-full px-8 py-4 bg-[#0068FF] text-white rounded-xl hover:bg-blue-600 
								transition-all duration-300 transform hover:scale-105 hover:shadow-lg 
								hover:shadow-blue-500/25 text-lg font-semibold'>
								Send Message
							</button>
						</form>
					</motion.div>
				</div>
			</section>

			{/* Footer */}
			<footer className='bg-[#0F1115] text-white py-12 border-t border-[#2A2F3C]'>
				<div className='container mx-auto px-4'>
					<div className='grid md:grid-cols-4 gap-8'>
						<div>
							<div className='flex items-center space-x-2 mb-4'>
								<img
									src={traq_logo}
									alt='TRAQ'
									className='h-8 w-auto'
								/>
								<h3 className='text-xl font-bold'>
									<Font family='Poppins' weight={800}>
										RSVP Hub
									</Font>
								</h3>
							</div>
							<p className='text-gray-400'>
								<Font family='Poppins' weight={400}>
									Making event management simple and
									beautiful.
								</Font>
							</p>
							<div className='mt-4 text-gray-400'>
								<p>
									<Font family='Poppins' weight={400}>
										Tetrice (Pty) Ltd.
									</Font>
								</p>
								<a
									href='mailto:info@traq.app'
									className='text-blue-400 hover:text-blue-300 transition-colors duration-300'>
									<Font family='Poppins' weight={400}>
										info@traq.app
									</Font>
								</a>
							</div>
						</div>
						<div>
							<h4 className='text-lg font-semibold mb-4'>
								<Font family='Poppins' weight={600}>
									Quick Links
								</Font>
							</h4>
							<ul className='space-y-2'>
								<li>
									<a
										href='#features'
										className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
										Features
									</a>
								</li>
								<li>
									<a
										href='#how-it-works'
										className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
										How it Works
									</a>
								</li>
								<li>
									<a
										href='#contact'
										className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
										Contact
									</a>
								</li>
							</ul>
						</div>
						<div>
							<h4 className='text-lg font-semibold mb-4'>
								<Font family='Poppins' weight={600}>
									Legal
								</Font>
							</h4>
							<ul className='space-y-2'>
								<li>
									<a
										href='#'
										className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
										Privacy Policy
									</a>
								</li>
								<li>
									<a
										href='#'
										className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
										Terms of Service
									</a>
								</li>
							</ul>
						</div>
						<div>
							<h4 className='text-lg font-semibold mb-4'>
								<Font family='Poppins' weight={600}>
									Connect
								</Font>
							</h4>
							<div className='flex space-x-4'>
								<a
									href='#'
									className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
									<span className='sr-only'>Twitter</span>
									<svg
										className='h-6 w-6'
										fill='currentColor'
										viewBox='0 0 24 24'>
										<path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84'></path>
									</svg>
								</a>
								<a
									href='#'
									className='text-gray-400 hover:text-blue-400 transition-colors duration-300'>
									<span className='sr-only'>GitHub</span>
									<svg
										className='h-6 w-6'
										fill='currentColor'
										viewBox='0 0 24 24'>
										<path
											fillRule='evenodd'
											d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
											clipRule='evenodd'></path>
									</svg>
								</a>
							</div>
						</div>
					</div>
					<div className='mt-12 pt-8 border-t border-[#2A2F3C] text-center text-gray-400'>
						<p>
							<Font family='Poppins' weight={800}>
								&copy; {new Date().getFullYear()} Tetrice (Pty)
								Ltd. All rights reserved.
							</Font>
						</p>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default LandingPage;
