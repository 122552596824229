import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	RiDashboardLine,
	RiMailSendLine,
	RiGroupLine,
	RiFileTextLine,
	RiPieChartLine,
	RiNotification3Line,
	RiUserLine,
	RiSearchLine,
	RiSettings4Line,
	RiQuestionLine,
	RiBellLine,
	RiLogoutBoxRLine,
	RiCalendarCheckLine,
} from "react-icons/ri";
import traqLogo from "../../assets/traq_logo.png";
import { useTheme } from "../../theme/ThemeContext";

const Sidebar = () => {
	const location = useLocation();
	const { theme } = useTheme();

	const navigation = [
		{
			name: "Dashboard",
			href: "/dashboard/home",
			icon: RiDashboardLine,
			description: "Overview & Statistics",
		},
		{
			name: "Campaigns",
			href: "/dashboard/campaigns",
			icon: RiMailSendLine,
			description: "Manage Events",
		},
		{
			name: "RSVPs",
			href: "/dashboard/rsvps",
			icon: RiCalendarCheckLine,
			description: "Response Management",
		},
		{
			name: "Guest Lists",
			href: "/dashboard/guests",
			icon: RiGroupLine,
			description: "Attendee Management",
		},
		{
			name: "Templates",
			href: "/dashboard/templates",
			icon: RiFileTextLine,
			description: "Email & Form Design",
		},
		{
			name: "Analytics",
			href: "/dashboard/analytics",
			icon: RiPieChartLine,
			description: "Insights & Reports",
		},
		{
			name: "Domains",
			href: "/dashboard/domains",
			icon: RiMailSendLine,
			description: "Email Domain Management",
		},
	];

	return (
		<div
			className={`h-full w-80 ${theme.background.secondary} border ${theme.border.primary} shadow-xl flex flex-col`}>
			<div
				className={`p-6 border-b ${theme.border.primary} flex items-center gap-3`}>
				<img
					src={traqLogo}
					alt='TRAQ'
					className='h-20 object-contain'
				/>
			</div>

			<div className='px-4 py-6'>
				<div className='relative mb-6'>
					<RiSearchLine
						className={`absolute left-4 top-1/2 transform -translate-y-1/2 ${theme.text.disabled} w-6 h-6`}
					/>
					<input
						type='text'
						placeholder='Search...'
						className={`w-full ${theme.background.tertiary} border ${theme.border.primary} rounded-lg py-3 pl-12 pr-4 
							${theme.text.secondary} placeholder:${theme.text.disabled} ${theme.accent.focus} focus:outline-none text-base`}
					/>
				</div>

				<nav className='space-y-3'>
					{navigation.map((item) => {
						const Icon = item.icon;
						const isActive = location.pathname === item.href;
						return (
							<Link
								key={item.name}
								to={item.href}
								className={`flex items-center px-5 py-4 rounded-lg transition-all duration-200 group
									${
										isActive
											? `${theme.accent.background} ${theme.accent.primary} ${theme.accent.border}`
											: `${theme.text.secondary} hover:${theme.accent.hoverBg} hover:${theme.accent.hoverText}`
									}`}>
								<Icon
									className={`w-7 h-7 ${
										isActive
											? theme.accent.primary
											: `${theme.text.tertiary} group-hover:${theme.accent.hoverText}`
									}`}
								/>
								<div className='ml-4'>
									<div className='text-base font-medium'>
										{item.name}
									</div>
									<div
										className={`text-sm ${theme.text.tertiary}`}>
										{item.description}
									</div>
								</div>
							</Link>
						);
					})}
				</nav>
			</div>

			<div className={`mt-auto border-t ${theme.border.primary} p-4`}>
				<Link
					to='/settings'
					className={`flex items-center px-5 py-4 ${theme.text.secondary} ${theme.background.hover} hover:${theme.text.primary} rounded-lg transition-all duration-300`}>
					<RiSettings4Line className='w-6 h-6' />
					<span className='ml-4 text-base'>Settings</span>
				</Link>
			</div>
		</div>
	);
};

const DashboardLayout = ({ children }) => {
	const { theme, toggleTheme, isDark } = useTheme();
	const navigate = useNavigate();
	const [userData, setUserData] = React.useState(null);

	React.useEffect(() => {
		// Get user data from session storage
		const userDataStr = sessionStorage.getItem("user_data");
		if (userDataStr) {
			const userDataObj = JSON.parse(userDataStr);
			setUserData(userDataObj);
		}
	}, []);

	const handleLogout = () => {
		// Clear the session storage
		sessionStorage.removeItem("accessToken");
		sessionStorage.removeItem("user_data");
		// Redirect to login
		navigate("/login");
	};

	return (
		<div className={`flex h-screen ${theme.background.primary}`}>
			<Sidebar />
			<div className='flex-1 flex flex-col overflow-hidden'>
				<header
					className={`h-24 ${theme.background.tertiary} backdrop-blur-sm border-b ${theme.border.primary} 
					flex items-center justify-between px-8 sticky top-0 z-10`}>
					<div className='flex items-center space-x-4'>
						<h1
							className={`${theme.text.primary} text-2xl font-bold`}>
							RSVP Management
						</h1>
						<span
							className={`${theme.text.primary} text-base ${theme.highlight.background} px-4 py-2 rounded-full`}>
							Pro Plan
						</span>
					</div>

					<div className='flex items-center space-x-5'>
						<button
							className={`p-4 ${theme.background.tertiary} ${theme.text.secondary} 
							group transition-all duration-200 rounded-xl border border-transparent 
							hover:${theme.accent.hoverBg} hover:${theme.accent.border} relative`}>
							<RiBellLine
								className={`w-7 h-7 group-hover:${theme.accent.hoverText}`}
							/>
							<span
								className={`absolute top-3 right-3 w-2.5 h-2.5 ${theme.highlight.background} rounded-full`}></span>
						</button>
						<button
							className={`p-4 ${theme.background.tertiary} ${theme.text.secondary} 
							group transition-all duration-200 rounded-xl border border-transparent 
							hover:${theme.accent.hoverBg} hover:${theme.accent.border}`}>
							<RiQuestionLine
								className={`w-7 h-7 group-hover:${theme.accent.hoverText}`}
							/>
						</button>
						<div
							onClick={handleLogout}
							className={`flex items-center space-x-4 px-5 py-3 
							${theme.background.tertiary} border ${theme.border.primary} 
							hover:${theme.accent.hoverBg} hover:${theme.accent.border}
							rounded-xl transition-all duration-200 cursor-pointer group`}>
							<img
								src={`https://ui-avatars.com/api/?name=${
									userData?.name || "User"
								}&background=random`}
								alt='Profile'
								className='w-10 h-10 rounded-full'
							/>
							<div className='flex flex-col'>
								<span
									className={`text-base ${theme.text.primary} group-hover:${theme.accent.hoverText}`}>
									{userData?.name || "Loading..."}
								</span>
								<span
									className={`text-sm ${theme.text.tertiary}`}>
									{userData?.email || "loading..."}
								</span>
							</div>
							<RiLogoutBoxRLine
								className={`w-6 h-6 ${theme.text.tertiary} group-hover:${theme.accent.hoverText}`}
							/>
						</div>
						<button
							onClick={toggleTheme}
							className={`p-4 ${theme.background.tertiary} ${theme.text.secondary} 
							group transition-all duration-200 rounded-xl border border-transparent 
							hover:${theme.accent.hoverBg} hover:${theme.accent.border}`}>
							<span
								className={`group-hover:${theme.accent.hoverText}`}>
								{isDark ? "☀️" : "🌙"}
							</span>
						</button>
					</div>
				</header>

				<main
					className={`flex-1 overflow-auto bg-gradient-to-b ${theme.background.gradient}`}>
					<div className='p-8 max-w-7xl mx-auto'>{children}</div>
				</main>
			</div>
		</div>
	);
};

export default DashboardLayout;
