import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import tick from "./assets/images/tick.png";

function formatPhoneNumber(phoneNumberString) {
	let rem271 = phoneNumberString.replace("+27 ", "0");
	let rem27 = rem271.replace("+27 ", "0");
	let remSpc = rem27.replace(/ /g, "");
	let cleaned = remSpc.replace(/\D/g, "");
	console.log(cleaned);
	// var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/); // ORIGINAL
	let match = cleaned.match(/^0(1|2|3|4|5|6|7|8){1}[0-9]{1}[0-9]{7}$/);

	if (match) {
		var intlCode = cleaned[1] ? "+27 " : "0";
		console.log("intlCode", intlCode);
		return `${intlCode}${cleaned[1]}${cleaned[2]} ${cleaned[3]}${cleaned[4]}${cleaned[5]} ${cleaned[6]}${cleaned[7]}${cleaned[8]}${cleaned[9]}`;
	} else {
		return cleaned;
	}
}

function validateNumber1(cell) {
	var cleaned = ("" + cell).replace(/\D/g, "");
	// var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/); // ORIGINAL
	// var match = cleaned.match(/^0(6|7|8){1}[0-9]{1}[0-9]{7}$/); // Other
	let match = cleaned.match(/^0(1|2|3|4|5|6|7|8){1}[0-9]{1}[0-9]{7}$/);
	return match;
}

function notSpecialChars(t) {
	return t.replace(/[^\w\s]/gi, "");
}

// Debounce function

export default class CellNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cell: "",
			cellValid: false,
		};
		this._timeout = 500;
	}

	render() {
		return (
			<View style={{ width: "100%" }}>
				<TextInput
					style={{
						flex: 1,

						padding: 14,
						paddingLeft: 40,
						marginTop: 0,
						fontSize: 16,
						borderRadius: 15,
						textAlign: this.props.textAlign,
						color: "#000",
						fontWeight: this.state.cellValid ? "500" : "200",
						fontFamily: "Avenir",
						letterSpacing: 0.26,
						borderWidth: this.state.emailValid ? 0.3 : 1,
						backgroundColor: "#FFF",
						borderColor: "#000",
					}}
					placeholderTextColor={"#797979"}
					autoCapitalize='none'
					clearButtonMode='while-editing'
					// autoCompleteType=''
					autoCorrect={false}
					keyboardType='number-pad'
					maxLength={15}
					autoFocus
					// autoComplete='tel'  // Put back later
					// textContentType='telephoneNumber' // Put back later
					placeholder={this.props.title}
					value={this.state.cell}
					onChangeText={async (text) => {
						this.setState({
							cell: text,
							cellValid: false,
						});

						clearTimeout(this._timeout);
						this._timeout = setTimeout(() => {
							let validated = formatPhoneNumber(text);
							const cellValid = validateNumber1(text);

							this.setState({
								cell: validated,
								cellValid: cellValid,
							});

							if (cellValid) {
								this.props.setParentState(validated);
								// console.log(validated);
							}
						}, 500);
					}}
				/>

				<Image
					source={tick}
					style={{
						width: 16,
						height: 16,
						resizeMode: "contain",
						position: "absolute",
						left: 15,
						top: 18,
						opacity: this.state.cellValid ? 1 : 0.2,
					}}
				/>
			</View>
		);
	}
}
