import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "../../theme/ThemeContext";
import moment from "moment";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
	RiCalendarCheckLine,
	RiFileExcel2Line,
	RiMailLine,
	RiCheckLine,
	RiCloseLine,
	RiQuestionLine,
	RiDownload2Line,
	RiAddLine,
	RiSearchLine,
	RiFilterLine,
	RiCloseFill,
	RiCheckFill,
	RiTimeLine,
	RiGroupLine,
	RiRefreshLine,
} from "react-icons/ri";
import { api_dashboard } from "../Api";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);

const RSVPs = () => {
	const { theme } = useTheme();
	const [campaigns, setCampaigns] = useState([]);
	const [selectedCampaign, setSelectedCampaign] = useState(null);
	const [rsvpList, setRsvpList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [exportLoading, setExportLoading] = useState(false);
	const [error, setError] = useState(null);
	const [stats, setStats] = useState({
		total: 0,
		accepted: 0,
		declined: 0,
		pending: 0,
	});
	const [chartData, setChartData] = useState({
		dietary: {},
		survey: {},
		contactMethod: {},
		marketing: {},
	});
	const [searchQuery, setSearchQuery] = useState("");
	const [filterStatus, setFilterStatus] = useState("all");

	// Fetch campaigns with RSVP functionality
	useEffect(() => {
		fetchCampaigns();
	}, []);

	const fetchCampaigns = async () => {
		try {
			setLoading(true);
			console.log("Fetching campaigns from:", api_dashboard.campaigns);

			const response = await axios.get(api_dashboard.campaigns, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
			});

			console.log("Campaign response:", response);

			// Filter campaigns with RSVP functionality if needed
			const rsvpCampaigns = response.data || [];
			setCampaigns(rsvpCampaigns);
			setError(null);
		} catch (err) {
			console.error("Error fetching RSVP campaigns:", err);
			setError(
				err.response?.data?.message ||
					"Failed to fetch RSVP campaigns. Please check your connection and try again.",
			);
			setCampaigns([]); // Set empty array on error
		} finally {
			setLoading(false);
		}
	};

	// Fetch RSVP list for a specific campaign
	const fetchRsvpList = async (campaignId) => {
		try {
			setLoading(true);
			console.log("Fetching RSVPs for campaign:", campaignId);

			const endpoint = api_dashboard.rsvps.getByEvent(campaignId);
			console.log("RSVP endpoint:", endpoint);

			const response = await axios.get(endpoint, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
			});

			console.log("RSVP response:", response);

			const rsvpData = response.data?.data || [];
			setRsvpList(rsvpData);

			// Calculate stats
			const accepted = rsvpData.filter(
				(rsvp) => rsvp.rsvpAccept === true,
			).length;
			const declined = rsvpData.filter(
				(rsvp) => rsvp.rsvpAccept === false,
			).length;
			const pending = rsvpData.filter(
				(rsvp) => rsvp.rsvpAccept === undefined,
			).length;

			setStats({
				total: response.data?.count || 0,
				accepted,
				declined,
				pending,
			});

			// Calculate chart data
			calculateChartData(rsvpData);

			setError(null);
		} catch (err) {
			console.error("Error fetching RSVP list:", err);
			setError(
				err.response?.data?.message ||
					"Failed to fetch RSVP list. Please check your connection and try again.",
			);
			setRsvpList([]);
		} finally {
			setLoading(false);
		}
	};

	// Handle campaign selection
	const handleSelectCampaign = (campaign) => {
		setSelectedCampaign(campaign);
		fetchRsvpList(campaign.id);
	};

	// Handle exporting RSVPs to guest list
	const handleExportToGuestList = async () => {
		if (!selectedCampaign || rsvpList.length === 0) return;

		try {
			setExportLoading(true);
			console.log(
				"Exporting RSVPs to guest list for campaign:",
				selectedCampaign.id,
			);

			// Format the data for guest list export
			const guestListData = {
				name: `${selectedCampaign.name} - RSVPs`,
				description: `Exported from ${
					selectedCampaign.name
				} campaign on ${moment().format("MMMM D, YYYY")}`,
				eventDate: selectedCampaign.eventDate,
				guests: rsvpList.map((rsvp) => ({
					name: rsvp.name,
					email: rsvp.email,
					phone: rsvp.phone || "",
					status: rsvp.status,
					additionalInfo: rsvp.additionalInfo || {},
				})),
			};

			// Use the correct API endpoint
			const response = await axios.post(
				api_dashboard.rsvps.exportToGuestList,
				guestListData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				},
			);

			console.log("Export response:", response);
			alert("RSVPs successfully exported to guest list!");
		} catch (err) {
			console.error("Error exporting to guest list:", err);
			setError(
				err.response?.data?.message ||
					"Failed to export to guest list. Please try again.",
			);
		} finally {
			setExportLoading(false);
		}
	};

	// Handle CSV export
	const handleExportCSV = () => {
		if (!selectedCampaign || rsvpList.length === 0) return;

		try {
			// Prepare CSV headers and data with more detailed information
			const headers = [
				"First Name",
				"Last Name",
				"Title",
				"Email",
				"Phone",
				"RSVP Status",
				"Response Date",
				"Dietary Requirements",
				"Survey Response",
				"Preferred Contact Method",
				"Marketing Opt-in",
			];

			// Convert RSVP data to CSV format with all available information
			const csvData = rsvpList.map((rsvp) => [
				rsvp.first_name || "",
				rsvp.last_name || "",
				rsvp.title_name || "",
				rsvp.email || "",
				rsvp.phone || "",
				rsvp.rsvpAccept ? "Accepted" : "Pending",
				rsvp.dateCreated
					? moment(rsvp.dateCreated).format("YYYY-MM-DD HH:mm")
					: "",
				rsvp.dietary || "",
				rsvp.survey_q1 || "",
				rsvp.preferredMethod || "",
				rsvp.optin !== undefined ? (rsvp.optin ? "Yes" : "No") : "",
			]);

			// Add headers to beginning of data
			csvData.unshift(headers);

			// Convert to CSV string, properly handling commas and quotes
			const csvContent = csvData
				.map((row) =>
					row
						.map((cell) => {
							// If cell contains commas, quotes, or newlines, wrap in quotes and escape existing quotes
							if (
								cell &&
								(cell.includes(",") ||
									cell.includes('"') ||
									cell.includes("\n"))
							) {
								return `"${cell.replace(/"/g, '""')}"`;
							}
							return cell;
						})
						.join(","),
				)
				.join("\n");

			// Create download link
			const blob = new Blob([csvContent], {
				type: "text/csv;charset=utf-8;",
			});
			const url = URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.setAttribute("href", url);
			link.setAttribute(
				"download",
				`${selectedCampaign.name}_RSVPs_${moment().format(
					"YYYY-MM-DD",
				)}.csv`,
			);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} catch (err) {
			console.error("Error exporting CSV:", err);
			setError("Failed to export CSV");
		}
	};

	// Filter RSVPs based on search and status filter
	const filteredRsvps = rsvpList.filter((rsvp) => {
		const matchesSearch =
			searchQuery === "" ||
			rsvp.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			rsvp.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			rsvp.email.toLowerCase().includes(searchQuery.toLowerCase());

		const matchesFilter =
			filterStatus === "all" || rsvp.status === filterStatus;

		return matchesSearch && matchesFilter;
	});

	// Get status badge style
	const getStatusBadge = (status) => {
		switch (status) {
			case "accepted":
				return "bg-green-100 text-green-800";
			case "declined":
				return "bg-red-100 text-red-800";
			case "pending":
				return "bg-yellow-100 text-yellow-800";
			default:
				return "bg-gray-100 text-gray-800";
		}
	};

	// Get status icon
	const getStatusIcon = (status) => {
		switch (status) {
			case "accepted":
				return <RiCheckFill className='w-4 h-4' />;
			case "declined":
				return <RiCloseFill className='w-4 h-4' />;
			case "pending":
				return <RiTimeLine className='w-4 h-4' />;
			default:
				return <RiQuestionLine className='w-4 h-4' />;
		}
	};

	// Add this function after fetchRsvpList
	const calculateChartData = (rsvpData) => {
		// Calculate dietary requirements stats
		const dietaryStats = rsvpData.reduce((acc, rsvp) => {
			const requirement = rsvp.dietary || "None Specified";
			acc[requirement] = (acc[requirement] || 0) + 1;
			return acc;
		}, {});

		// Calculate survey response stats
		const surveyStats = rsvpData.reduce((acc, rsvp) => {
			const response = rsvp.survey_q1 || "No Response";
			acc[response] = (acc[response] || 0) + 1;
			return acc;
		}, {});

		// Calculate contact method stats
		const contactStats = rsvpData.reduce((acc, rsvp) => {
			const method = rsvp.preferredMethod || "Not Specified";
			acc[method] = (acc[method] || 0) + 1;
			return acc;
		}, {});

		// Calculate marketing opt-in stats
		const marketingStats = rsvpData.reduce((acc, rsvp) => {
			const optIn =
				rsvp.optin !== undefined
					? rsvp.optin
						? "Opted In"
						: "Opted Out"
					: "Not Specified";
			acc[optIn] = (acc[optIn] || 0) + 1;
			return acc;
		}, {});

		setChartData({
			dietary: createChartData(dietaryStats, "Dietary Requirements"),
			survey: createChartData(surveyStats, "Survey Responses"),
			contactMethod: createChartData(
				contactStats,
				"Preferred Contact Method",
			),
			marketing: createChartData(marketingStats, "Marketing Opt-in"),
		});
	};

	const createChartData = (data, label) => {
		const colors = [
			"rgba(255, 99, 132, 0.8)",
			"rgba(54, 162, 235, 0.8)",
			"rgba(255, 206, 86, 0.8)",
			"rgba(75, 192, 192, 0.8)",
			"rgba(153, 102, 255, 0.8)",
			"rgba(255, 159, 64, 0.8)",
		];

		return {
			labels: Object.keys(data),
			datasets: [
				{
					label,
					data: Object.values(data),
					backgroundColor: colors[0],
					borderColor:
						theme.background.primary === "bg-white"
							? "#fff"
							: "#1f2937",
					borderWidth: 1,
				},
			],
		};
	};

	if (loading && !selectedCampaign) {
		return (
			<div className='flex items-center justify-center h-64'>
				<div className='text-gray-400'>Loading RSVP campaigns...</div>
			</div>
		);
	}

	return (
		<div className='space-y-8'>
			<div className='flex justify-between items-center'>
				<h2 className={`text-2xl font-bold ${theme.text.primary}`}>
					RSVP Management
				</h2>
			</div>

			{error && (
				<div
					className={`rounded-lg border border-red-300 bg-red-50 p-4 ${theme.text.error}`}>
					{error}
				</div>
			)}

			{/* Select campaign section */}
			{!selectedCampaign ? (
				<div
					className={`rounded-xl border ${theme.border.primary} ${theme.background.secondary} overflow-hidden`}>
					<div className='p-6'>
						<h3
							className={`text-xl font-semibold ${theme.text.primary} mb-4`}>
							Select a Campaign to View RSVPs
						</h3>

						<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6'>
							{campaigns.map((campaign) => (
								<div
									key={campaign.id}
									onClick={() =>
										handleSelectCampaign(campaign)
									}
									className={`p-4 border ${theme.border.primary} rounded-lg cursor-pointer
                                        transition-all duration-200 hover:border-blue-500
                                        ${theme.background.primary} hover:${theme.background.hover}`}>
									<div className='flex items-center gap-3 mb-2'>
										<div
											className={`p-2 rounded-lg ${theme.accent.background}`}>
											<RiMailLine
												className={`w-5 h-5 ${theme.accent.primary}`}
											/>
										</div>
										<div
											className={`text-lg font-medium ${theme.text.primary}`}>
											{campaign.name}
										</div>
									</div>
									<div
										className={`text-sm ${theme.text.secondary} ml-10`}>
										Sent on{" "}
										{moment(campaign.sendDate).format(
											"MMM D, YYYY",
										)}
									</div>
									<div
										className={`text-sm ${theme.text.secondary} ml-10`}>
										{campaign.recipientCount} recipients
									</div>
									<div
										className={`text-sm ${theme.text.secondary} ml-10`}>
										{campaign.id}
									</div>
								</div>
							))}
						</div>

						{campaigns.length === 0 && (
							<div className='text-center py-8'>
								<RiCalendarCheckLine
									className={`w-16 h-16 mx-auto ${theme.text.secondary} opacity-50`}
								/>
								<p className={`mt-4 ${theme.text.secondary}`}>
									No RSVP campaigns found. Create a campaign
									with RSVP functionality first.
								</p>
							</div>
						)}
					</div>
				</div>
			) : (
				<>
					{/* RSVP List header with stats */}
					<div
						className={`rounded-xl border ${theme.border.primary} ${theme.background.secondary} overflow-hidden`}>
						<div className='p-6'>
							<div className='flex justify-between items-center'>
								<div>
									<div className='flex items-center gap-2'>
										<button
											onClick={() =>
												setSelectedCampaign(null)
											}
											className={`mr-2 p-2 rounded-full ${theme.background.hover}`}>
											<RiCloseLine className='w-5 h-5' />
										</button>
										<h3
											className={`text-xl font-semibold ${theme.text.primary}`}>
											{selectedCampaign.name} - RSVPs
										</h3>
									</div>
									<p
										className={`mt-1 ${theme.text.secondary}`}>
										Campaign sent on{" "}
										{moment(
											selectedCampaign.sendDate,
										).format("MMMM D, YYYY")}
									</p>
									<p
										className={`mt-1 ${theme.text.secondary}`}>
										Campaign ID: {selectedCampaign.id}
									</p>
								</div>
								<div className='flex gap-3'>
									<a
										href='https://rsvp.traq.app/event/wYNk2vIvTFqZLaOLpx4D'
										target='_blank'
										rel='noopener noreferrer'
										className={`px-4 py-2 rounded-lg flex items-center gap-2 
                                            ${theme.background.tertiary} ${theme.text.primary}
                                            hover:${theme.background.hover}`}>
										<RiCalendarCheckLine className='w-5 h-5' />
										Preview RSVP
									</a>
									<button
										onClick={() =>
											fetchRsvpList(selectedCampaign.id)
										}
										className={`px-4 py-2 rounded-lg flex items-center gap-2 
                                            ${theme.background.tertiary} ${theme.text.primary}
                                            hover:${theme.background.hover}`}>
										<RiRefreshLine
											className={`w-5 h-5 ${
												loading ? "animate-spin" : ""
											}`}
										/>
										Refresh
									</button>
									<button
										onClick={handleExportToGuestList}
										disabled={
											exportLoading ||
											rsvpList.length === 0
										}
										className={`px-4 py-2 rounded-lg flex items-center gap-2 
                                            ${theme.background.tertiary} ${
											theme.text.primary
										}
                                            ${
												exportLoading ||
												rsvpList.length === 0
													? "opacity-50 cursor-not-allowed"
													: ""
											}`}>
										<RiGroupLine className='w-5 h-5' />
										Export to Guest List
									</button>
									<button
										onClick={handleExportCSV}
										disabled={rsvpList.length === 0}
										className={`px-4 py-2 rounded-lg flex items-center gap-2 
                                            ${theme.highlight.background} ${
											theme.text.primary
										}
                                            ${
												rsvpList.length === 0
													? "opacity-50 cursor-not-allowed"
													: ""
											}`}>
										<RiFileExcel2Line className='w-5 h-5' />
										Export CSV
									</button>
								</div>
							</div>

							{/* Stats cards */}
							<div className='grid grid-cols-1 md:grid-cols-4 gap-4 mt-6'>
								<div
									className={`p-4 rounded-lg border ${theme.border.primary} ${theme.background.tertiary}`}>
									<div
										className={`text-sm ${theme.text.secondary}`}>
										Total Responses
									</div>
									<div
										className={`text-2xl font-semibold ${theme.text.primary}`}>
										{stats.total}
									</div>
								</div>
								<div className='p-4 rounded-lg border border-green-200 bg-green-50'>
									<div className='text-sm text-green-600'>
										Accepted
									</div>
									<div className='text-2xl font-semibold text-green-700'>
										{stats.accepted}
									</div>
									<div className='text-sm text-green-600'>
										{stats.total > 0
											? Math.round(
													(stats.accepted /
														stats.total) *
														100,
											  )
											: 0}
										%
									</div>
								</div>
								<div className='p-4 rounded-lg border border-red-200 bg-red-50'>
									<div className='text-sm text-red-600'>
										Declined
									</div>
									<div className='text-2xl font-semibold text-red-700'>
										{stats.declined}
									</div>
									<div className='text-sm text-red-600'>
										{stats.total > 0
											? Math.round(
													(stats.declined /
														stats.total) *
														100,
											  )
											: 0}
										%
									</div>
								</div>
								<div className='p-4 rounded-lg border border-yellow-200 bg-yellow-50'>
									<div className='text-sm text-yellow-600'>
										Pending
									</div>
									<div className='text-2xl font-semibold text-yellow-700'>
										{stats.pending}
									</div>
									<div className='text-sm text-yellow-600'>
										{stats.total > 0
											? Math.round(
													(stats.pending /
														stats.total) *
														100,
											  )
											: 0}
										%
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Charts Section */}
					{selectedCampaign && !loading && rsvpList.length > 0 && (
						<div
							className={`rounded-xl border ${theme.border.primary} ${theme.background.secondary} overflow-hidden`}>
							<div className='p-6'>
								<h3
									className={`text-xl font-semibold ${theme.text.primary} mb-4`}>
									RSVP Analytics
								</h3>
								<div className='grid grid-cols-2 gap-4'>
									<div
										className={`p-4 ${theme.background.primary} rounded-lg shadow`}>
										<h4
											className={`text-sm font-medium ${theme.text.primary} mb-2`}>
											Dietary Requirements
										</h4>
										<div className='h-48'>
											<Bar
												data={chartData.dietary}
												options={{
													maintainAspectRatio: false,
													indexAxis: "y",
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor:
																theme.background
																	.primary ===
																"bg-white"
																	? "rgba(255, 255, 255, 0.9)"
																	: "rgba(17, 24, 39, 0.9)",
															titleColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															bodyColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															borderColor:
																theme.border
																	.primary ===
																"border-gray-200"
																	? "#e5e7eb"
																	: "#374151",
															borderWidth: 1,
														},
													},
													scales: {
														x: {
															grid: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "rgba(0,0,0,0.1)"
																		: "rgba(255,255,255,0.1)",
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
														y: {
															grid: {
																display: false,
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
													},
												}}
											/>
										</div>
									</div>
									<div
										className={`p-4 ${theme.background.primary} rounded-lg shadow`}>
										<h4
											className={`text-sm font-medium ${theme.text.primary} mb-2`}>
											Survey Responses
										</h4>
										<div className='h-48'>
											<Bar
												data={chartData.survey}
												options={{
													maintainAspectRatio: false,
													indexAxis: "y",
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor:
																theme.background
																	.primary ===
																"bg-white"
																	? "rgba(255, 255, 255, 0.9)"
																	: "rgba(17, 24, 39, 0.9)",
															titleColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															bodyColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															borderColor:
																theme.border
																	.primary ===
																"border-gray-200"
																	? "#e5e7eb"
																	: "#374151",
															borderWidth: 1,
														},
													},
													scales: {
														x: {
															grid: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "rgba(0,0,0,0.1)"
																		: "rgba(255,255,255,0.1)",
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
														y: {
															grid: {
																display: false,
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
													},
												}}
											/>
										</div>
									</div>
									<div
										className={`p-4 ${theme.background.primary} rounded-lg shadow`}>
										<h4
											className={`text-sm font-medium ${theme.text.primary} mb-2`}>
											Contact Method Preferences
										</h4>
										<div className='h-48'>
											<Bar
												data={chartData.contactMethod}
												options={{
													maintainAspectRatio: false,
													indexAxis: "y",
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor:
																theme.background
																	.primary ===
																"bg-white"
																	? "rgba(255, 255, 255, 0.9)"
																	: "rgba(17, 24, 39, 0.9)",
															titleColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															bodyColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															borderColor:
																theme.border
																	.primary ===
																"border-gray-200"
																	? "#e5e7eb"
																	: "#374151",
															borderWidth: 1,
														},
													},
													scales: {
														x: {
															grid: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "rgba(0,0,0,0.1)"
																		: "rgba(255,255,255,0.1)",
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
														y: {
															grid: {
																display: false,
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
													},
												}}
											/>
										</div>
									</div>
									<div
										className={`p-4 ${theme.background.primary} rounded-lg shadow`}>
										<h4
											className={`text-sm font-medium ${theme.text.primary} mb-2`}>
											Marketing Opt-in Status
										</h4>
										<div className='h-48'>
											<Bar
												data={chartData.marketing}
												options={{
													maintainAspectRatio: false,
													indexAxis: "y",
													plugins: {
														legend: {
															display: false,
														},
														tooltip: {
															backgroundColor:
																theme.background
																	.primary ===
																"bg-white"
																	? "rgba(255, 255, 255, 0.9)"
																	: "rgba(17, 24, 39, 0.9)",
															titleColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															bodyColor:
																theme.text
																	.primary ===
																"text-gray-900"
																	? "#111827"
																	: "#fff",
															borderColor:
																theme.border
																	.primary ===
																"border-gray-200"
																	? "#e5e7eb"
																	: "#374151",
															borderWidth: 1,
														},
													},
													scales: {
														x: {
															grid: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "rgba(0,0,0,0.1)"
																		: "rgba(255,255,255,0.1)",
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
														y: {
															grid: {
																display: false,
															},
															ticks: {
																color:
																	theme.text
																		.primary ===
																	"text-gray-900"
																		? "#111827"
																		: "#fff",
																font: {
																	size: 10,
																},
															},
														},
													},
												}}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{/* RSVP List table */}
					<div
						className={`rounded-xl border ${theme.border.primary} ${theme.background.secondary} overflow-hidden`}>
						<div className='p-4'>
							<div className='flex items-center justify-between mb-4'>
								<div className='relative flex-1 max-w-md'>
									<RiSearchLine
										className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${theme.text.disabled}`}
									/>
									<input
										type='text'
										placeholder='Search by name or email...'
										value={searchQuery}
										onChange={(e) =>
											setSearchQuery(e.target.value)
										}
										className={`w-full pl-10 pr-4 py-2 rounded-lg ${theme.background.tertiary} 
                                                  border ${theme.border.primary} ${theme.text.primary} ${theme.accent.focus}`}
									/>
								</div>
								<div className='flex gap-2'>
									<select
										value={filterStatus}
										onChange={(e) =>
											setFilterStatus(e.target.value)
										}
										className={`px-3 py-2 rounded-lg ${theme.background.tertiary} 
                                                  border ${theme.border.primary} ${theme.text.primary}`}>
										<option value='all'>
											All Responses
										</option>
										<option value='accepted'>
											Accepted
										</option>
										<option value='declined'>
											Declined
										</option>
										<option value='pending'>Pending</option>
									</select>
								</div>
							</div>

							{loading ? (
								<div className='flex items-center justify-center h-64'>
									<div className='text-gray-400'>
										Loading RSVPs...
									</div>
								</div>
							) : (
								<>
									{filteredRsvps.length > 0 ? (
										<div className='overflow-x-auto'>
											<table className='w-full'>
												<thead
													className={`${theme.background.tertiary} border-b ${theme.border.primary}`}>
													<tr>
														<th
															className={`px-6 py-3 text-left text-xs font-medium ${theme.text.secondary} uppercase tracking-wider`}>
															Guest
														</th>
														<th
															className={`px-6 py-3 text-left text-xs font-medium ${theme.text.secondary} uppercase tracking-wider`}>
															Status
														</th>
														<th
															className={`px-6 py-3 text-left text-xs font-medium ${theme.text.secondary} uppercase tracking-wider`}>
															Response Date
														</th>
														<th
															className={`px-6 py-3 text-left text-xs font-medium ${theme.text.secondary} uppercase tracking-wider`}>
															Additional Info
														</th>
													</tr>
												</thead>
												<tbody
													className={`${theme.background.primary} divide-y ${theme.border.primary}`}>
													{filteredRsvps.map(
														(rsvp) => (
															<tr
																key={rsvp.id}
																className={`${theme.background.primary} hover:${theme.background.hover} transition-colors duration-200`}>
																<td className='px-6 py-4 whitespace-nowrap'>
																	<div className='flex flex-col'>
																		<div
																			className={`text-sm font-medium ${theme.text.primary}`}>
																			{
																				rsvp.first_name
																			}{" "}
																			{
																				rsvp.last_name
																			}
																		</div>
																		<div
																			className={`text-sm ${theme.text.secondary}`}>
																			{
																				rsvp.email
																			}
																		</div>
																		<div
																			className={`text-sm ${theme.text.tertiary}`}>
																			{
																				rsvp.title_name
																			}
																		</div>
																	</div>
																</td>
																<td className='px-6 py-4 whitespace-nowrap'>
																	<span
																		className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadge(
																			rsvp.rsvpAccept
																				? "accepted"
																				: "pending",
																		)}`}>
																		<span className='flex items-center gap-1'>
																			{getStatusIcon(
																				rsvp.rsvpAccept
																					? "accepted"
																					: "pending",
																			)}
																			{rsvp.rsvpAccept
																				? "Submitted"
																				: "Pending"}
																		</span>
																	</span>
																</td>
																<td
																	className={`px-6 py-4 whitespace-nowrap text-sm ${theme.text.secondary}`}>
																	{rsvp.dateCreated
																		? moment(
																				rsvp.dateCreated,
																		  ).format(
																				"MMM D, YYYY h:mm A",
																		  )
																		: "-"}
																</td>
																<td
																	className={`px-6 py-4 text-sm ${theme.text.secondary}`}>
																	<div className='max-w-xs'>
																		{rsvp.dietary && (
																			<div className='flex justify-between py-1'>
																				<span
																					className={`font-medium ${theme.text.primary}`}>
																					Dietary
																					Requirements:
																				</span>
																				<span>
																					{
																						rsvp.dietary
																					}
																				</span>
																			</div>
																		)}
																		{rsvp.survey_q1 && (
																			<div className='flex justify-between py-1'>
																				<span
																					className={`font-medium ${theme.text.primary}`}>
																					Survey
																					Response:
																				</span>
																				<span>
																					{
																						rsvp.survey_q1
																					}
																				</span>
																			</div>
																		)}
																		{rsvp.preferredMethod && (
																			<div className='flex justify-between py-1'>
																				<span
																					className={`font-medium ${theme.text.primary}`}>
																					Contact
																					Method:
																				</span>
																				<span>
																					{
																						rsvp.preferredMethod
																					}
																				</span>
																			</div>
																		)}
																		{rsvp.optin !==
																			undefined && (
																			<div className='flex justify-between py-1'>
																				<span
																					className={`font-medium ${theme.text.primary}`}>
																					Marketing
																					Opt-in:
																				</span>
																				<span>
																					{rsvp.optin
																						? "Yes"
																						: "No"}
																				</span>
																			</div>
																		)}
																	</div>
																</td>
															</tr>
														),
													)}
												</tbody>
											</table>
										</div>
									) : (
										<div className='text-center py-12'>
											<RiCalendarCheckLine
												className={`w-16 h-16 mx-auto ${theme.text.secondary} opacity-30`}
											/>
											<p
												className={`mt-4 ${theme.text.secondary}`}>
												{searchQuery ||
												filterStatus !== "all"
													? "No RSVPs match your search or filter criteria"
													: "No RSVPs found for this campaign"}
											</p>
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default RSVPs;
