import React, { useState, useEffect } from "react";
import { api_dashboard } from "../Api";
import { RiDeleteBin6Line, RiEditLine, RiEyeLine } from "react-icons/ri";
import { Dialog } from "@headlessui/react";

const Templates = () => {
	const [templates, setTemplates] = useState([]);
	const [loading, setLoading] = useState(true);
	const [showPreview, setShowPreview] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		type: "Email",
		content: "",
		subject: "",
	});
	const [isFullPreviewOpen, setIsFullPreviewOpen] = useState(false);
	const [isFormModalOpen, setIsFormModalOpen] = useState(false);

	useEffect(() => {
		fetchTemplates();
	}, []);

	const fetchTemplates = async () => {
		try {
			const response = await fetch(api_dashboard.templates.list, {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			const data = await response.json();
			console.log(data);
			setTemplates(Array.isArray(data.templates) ? data.templates : []);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching templates:", error);
			setTemplates([]);
			setLoading(false);
			alert("Failed to load templates");
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const url = isEditing
				? api_dashboard.templates.update(selectedTemplate.id)
				: api_dashboard.templates.create;
			const method = isEditing ? "PUT" : "POST";

			const templateData = {
				name: formData.name,
				html: formData.content,
				subject: formData.subject,
				status: "ACTIVE",
				type: formData.type,
			};

			const response = await fetch(url, {
				method,
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${sessionStorage.getItem(
						"accessToken",
					)}`,
				},
				body: JSON.stringify(templateData),
			});

			if (response.ok) {
				fetchTemplates();
				resetForm();
			} else {
				const errorData = await response.json();
				if (errorData.errors) {
					const errorMessages = errorData.errors
						.map((err) => err.msg)
						.join("\n");
					alert(errorMessages);
				} else {
					alert(errorData.message || "Failed to save template");
				}
			}
		} catch (error) {
			console.error("Error saving template:", error);
			alert("Failed to save template");
		}
	};

	const handleDelete = async (id) => {
		if (window.confirm("Are you sure you want to delete this template?")) {
			try {
				await fetch(api_dashboard.templates.delete(id), {
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				});
				fetchTemplates();
			} catch (error) {
				console.error("Error deleting template:", error);
			}
		}
	};

	const handleEdit = (template) => {
		setSelectedTemplate(template);
		setFormData({
			name: template.name,
			type: template.type,
			content: template.html,
			subject: template.subject,
		});
		setIsEditing(true);
		setIsFormModalOpen(true);
	};

	const handlePreview = async (template) => {
		try {
			const response = await fetch(
				api_dashboard.templates.preview(template.id),
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem(
							"accessToken",
						)}`,
					},
				},
			);
			const data = await response.json();
			const previewContent = data.preview || template.html;

			const fullHtml = previewContent.includes("<html")
				? previewContent
				: `
					<!DOCTYPE html>
					<html>
					<head>
						<meta charset="UTF-8">
						<meta name="viewport" content="width=device-width, initial-scale=1.0">
					</head>
					<body style="margin: 0; padding: 20px;">
						${previewContent}
					</body>
					</html>
				`;

			setSelectedTemplate({
				...template,
				preview: fullHtml,
			});
			setIsFullPreviewOpen(true);
		} catch (error) {
			console.error("Error fetching preview:", error);
			const fallbackHtml = `
				<!DOCTYPE html>
				<html>
				<head>
					<meta charset="UTF-8">
					<meta name="viewport" content="width=device-width, initial-scale=1.0">
				</head>
				<body style="margin: 0; padding: 20px;">
					${template.html}
				</body>
				</html>
			`;
			setSelectedTemplate({
				...template,
				preview: fallbackHtml,
			});
			setIsFullPreviewOpen(true);
		}
	};

	const resetForm = () => {
		setFormData({
			name: "",
			type: "Email",
			content: "",
			subject: "",
		});
		setIsEditing(false);
		setSelectedTemplate(null);
		setShowPreview(false);
	};

	return (
		<div className='space-y-6'>
			<div className='flex justify-between items-center'>
				<h2 className='text-2xl font-semibold text-white'>Templates</h2>
				<button
					onClick={() => {
						setIsFormModalOpen(true);
						resetForm();
					}}
					className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90'>
					Create Template
				</button>
			</div>

			{/* Template Form Modal */}
			<Dialog
				open={isFormModalOpen}
				onClose={() => {
					setIsFormModalOpen(false);
					resetForm();
				}}
				className='relative z-50'>
				<div className='fixed inset-0 bg-black/70' aria-hidden='true' />

				<div className='fixed inset-0 flex items-center justify-center p-4'>
					<Dialog.Panel className='bg-dark rounded-lg w-full max-w-2xl'>
						<div className='p-6'>
							<div className='flex justify-between items-center mb-4'>
								<Dialog.Title className='text-xl font-medium text-white'>
									{isEditing
										? "Edit Template"
										: "Create New Template"}
								</Dialog.Title>
								<button
									onClick={() => {
										setIsFormModalOpen(false);
										resetForm();
									}}
									className='text-gray-400 hover:text-white'>
									✕
								</button>
							</div>

							<form onSubmit={handleSubmit} className='space-y-4'>
								<div>
									<label className='block text-sm font-medium text-gray-200'>
										Template Name
									</label>
									<input
										type='text'
										value={formData.name}
										onChange={(e) =>
											setFormData({
												...formData,
												name: e.target.value,
											})
										}
										className='mt-1 w-full rounded-md bg-gray-800 border-gray-700 text-white'
										required
									/>
								</div>
								<div>
									<label className='block text-sm font-medium text-gray-200'>
										Subject
									</label>
									<input
										type='text'
										value={formData.subject}
										onChange={(e) =>
											setFormData({
												...formData,
												subject: e.target.value,
											})
										}
										className='mt-1 w-full rounded-md bg-gray-800 border-gray-700 text-white'
										required
									/>
								</div>
								<div>
									<label className='block text-sm font-medium text-gray-200'>
										Content (HTML)
									</label>
									<textarea
										value={formData.content}
										onChange={(e) =>
											setFormData({
												...formData,
												content: e.target.value,
											})
										}
										rows={10}
										className='mt-1 w-full rounded-md bg-gray-800 border-gray-700 text-white font-mono'
										required
									/>
								</div>
								<div className='flex justify-end space-x-3'>
									<button
										type='button'
										onClick={() => {
											setIsFormModalOpen(false);
											resetForm();
										}}
										className='px-4 py-2 text-white rounded-lg hover:bg-gray-700'>
										Cancel
									</button>
									<button
										type='submit'
										className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90'>
										{isEditing
											? "Update Template"
											: "Save Template"}
									</button>
								</div>
							</form>
						</div>
					</Dialog.Panel>
				</div>
			</Dialog>

			{/* Templates Grid */}
			<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
				{loading ? (
					<div className='col-span-full flex justify-center items-center py-8'>
						<div className='text-white'>Loading templates...</div>
					</div>
				) : templates.length === 0 ? (
					<div className='col-span-full flex justify-center items-center py-8'>
						<div className='text-white'>No templates found</div>
					</div>
				) : (
					templates.map((template) => (
						<div
							key={template.id}
							className='bg-dark p-6 rounded-lg shadow-lg space-y-4'>
							<div className='flex justify-between items-start'>
								<div>
									<h3 className='text-lg font-medium text-white'>
										{template.name}
									</h3>
									<p className='text-sm text-gray-400 mt-1'>
										{template.type}
									</p>
									<p className='text-xs text-gray-500 mt-1'>
										Last used: {template.lastUsed}
									</p>
								</div>
								<div className='flex space-x-2'>
									<button
										onClick={() => handlePreview(template)}
										className='text-gray-400 hover:text-white'>
										<RiEyeLine className='w-5 h-5' />
									</button>
									<button
										onClick={() => handleEdit(template)}
										className='text-gray-400 hover:text-white'>
										<RiEditLine className='w-5 h-5' />
									</button>
									<button
										onClick={() =>
											handleDelete(template.id)
										}
										className='text-gray-400 hover:text-red-500'>
										<RiDeleteBin6Line className='w-5 h-5' />
									</button>
								</div>
							</div>

							{/* Small Preview */}
							<div className='bg-white rounded-lg overflow-hidden h-48'>
								<div className='bg-gray-100 p-2 border-b border-gray-200 flex justify-between items-center'>
									<div className='flex space-x-1'>
										<div className='w-2 h-2 rounded-full bg-red-500'></div>
										<div className='w-2 h-2 rounded-full bg-yellow-500'></div>
										<div className='w-2 h-2 rounded-full bg-green-500'></div>
									</div>
									<span className='text-xs text-gray-600'>
										Preview
									</span>
								</div>
								<iframe
									srcDoc={template.html}
									className='w-full h-40 border-0 pointer-events-none'
									title={`${template.name} Preview`}
									sandbox='allow-same-origin'
								/>
							</div>
						</div>
					))
				)}
			</div>

			{/* Preview Modal */}
			{showPreview && (
				<Dialog
					open={isFullPreviewOpen}
					onClose={() => setIsFullPreviewOpen(false)}
					className='relative z-50'>
					<div
						className='fixed inset-0 bg-black/70'
						aria-hidden='true'
					/>

					<div className='fixed inset-0 flex items-center justify-center p-4'>
						<Dialog.Panel className='bg-dark rounded-lg w-full max-w-4xl'>
							<div className='p-6'>
								<div className='flex justify-between items-center mb-4'>
									<div>
										<h3 className='text-xl font-medium text-white'>
											{selectedTemplate?.name}
										</h3>
										<p className='text-sm text-gray-400 mt-1'>
											Subject: {selectedTemplate?.subject}
										</p>
									</div>
									<button
										onClick={() =>
											setIsFullPreviewOpen(false)
										}
										className='px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90'>
										Close Preview
									</button>
								</div>
								<div className='bg-white rounded-lg overflow-hidden'>
									<div className='bg-gray-100 p-3 border-b border-gray-200 flex justify-between items-center'>
										<div className='flex space-x-2'>
											<div className='w-3 h-3 rounded-full bg-red-500'></div>
											<div className='w-3 h-3 rounded-full bg-yellow-500'></div>
											<div className='w-3 h-3 rounded-full bg-green-500'></div>
										</div>
										<span className='text-sm text-gray-600'>
											Email Preview
										</span>
									</div>
									<iframe
										srcDoc={selectedTemplate?.preview}
										className='w-full h-[70vh] border-0'
										title='Template Preview'
										sandbox='allow-same-origin allow-scripts'
										style={{
											backgroundColor: "white",
											margin: "0 auto",
											display: "block",
										}}
									/>
								</div>
							</div>
						</Dialog.Panel>
					</div>
				</Dialog>
			)}
		</div>
	);
};

export default Templates;
