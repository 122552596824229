import React, { useState, useEffect } from "react";
import { useTheme } from "../../theme/ThemeContext";
import axios from "axios";
import { api_dashboard } from "../Api";
import {
	RiMailLine,
	RiUserLine,
	RiFileTextLine,
	RiGlobalLine,
	RiCheckLine,
} from "react-icons/ri";

const NewCampaignForm = ({ onClose, onCampaignCreated }) => {
	const { theme } = useTheme();
	const [currentStep, setCurrentStep] = useState(1);
	const [templates, setTemplates] = useState([]);
	const [guestLists, setGuestLists] = useState([]);
	const [domains, setDomains] = useState([]);
	const [formData, setFormData] = useState({
		name: "",
		sendDate: "",
		templateId: "",
		guestListId: "",
		domainId: "",
		subject: "",
	});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectedTemplate, setSelectedTemplate] = useState(null);

	// Add validation state
	const [stepValidation, setStepValidation] = useState({
		1: false,
		2: false,
		3: false,
		4: false,
	});

	// Validate current step before allowing next
	const validateStep = (step) => {
		switch (step) {
			case 1:
				return formData.name && formData.sendDate;
			case 2:
				return formData.templateId;
			case 3:
				return formData.guestListId;
			case 4:
				return formData.domainId && formData.subject;
			default:
				return false;
		}
	};

	useEffect(() => {
		// Fetch required data
		const fetchData = async () => {
			try {
				const token = sessionStorage.getItem("accessToken");

				// Fetch templates and guest lists first
				const [templatesRes, guestListsRes] = await Promise.all([
					axios.get(api_dashboard.templates.list, {
						headers: { Authorization: `Bearer ${token}` },
					}),
					axios.get(api_dashboard.guestLists.list, {
						headers: { Authorization: `Bearer ${token}` },
					}),
				]);

				// Transform template data
				const templatesWithPreviews =
					templatesRes.data?.templates?.map((template) => ({
						id: template.id,
						name: template.name,
						description: template.description,
						preview:
							template.preview ||
							template.thumbnail ||
							"/placeholder-template.png",
						createdAt:
							template.createdAt || new Date().toISOString(),
						updatedAt:
							template.updatedAt || new Date().toISOString(),
						type: template.type,
					})) || [];

				// Handle guest lists data
				const lists = Array.isArray(guestListsRes.data)
					? guestListsRes.data
					: guestListsRes.data?.lists || [];

				// For now, use a default domain until the API is ready
				const defaultDomain = {
					id: "default",
					domain: "elveeconsultancy.co",
					email: "events@elveeconsultancy.co",
					verified: true,
					status: "active",
					usedToday: 0,
					dailyLimit: 2000,
					lastUsed: new Date().toISOString(),
				};

				setTemplates(templatesWithPreviews);
				setGuestLists(lists);
				setDomains([defaultDomain]); // Use default domain for now
			} catch (error) {
				console.error("Error fetching data:", error);
				setError("Failed to load required data");
			}
		};
		fetchData();
	}, []);

	useEffect(() => {
		// Update validation state when form data changes
		setStepValidation((prev) => ({
			...prev,
			[currentStep]: validateStep(currentStep),
		}));
	}, [formData, currentStep]);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleTemplateSelect = (template) => {
		console.log("Selected template:", template);
		setSelectedTemplate(template);
		setFormData((prev) => ({
			...prev,
			templateId: template.id,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError(null);

		try {
			const token = sessionStorage.getItem("accessToken");

			// Find the selected guest list
			const selectedList = guestLists.find(
				(list) => list.id === formData.guestListId,
			);

			// Format the data according to the API requirements
			const campaignData = {
				name: formData.name,
				sendDate: new Date(formData.sendDate).toISOString(),
				templateId: formData.templateId,
				guestListId: formData.guestListId,
				subject: formData.subject,
			};

			console.log("Campaign data:", campaignData);

			const response = await axios.post(
				api_dashboard.createCampaign,
				campaignData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				},
			);

			if (response.data) {
				await onCampaignCreated(response.data);
				onClose();
			} else {
				throw new Error("No data received from server");
			}
		} catch (err) {
			console.error("Campaign creation error:", err);
			setError(
				err.response?.data?.message ||
					err.message ||
					"Failed to create campaign",
			);
		} finally {
			setLoading(false);
		}
	};

	const handleNext = () => {
		if (validateStep(currentStep)) {
			setCurrentStep(currentStep + 1);
		}
	};

	const steps = [
		{
			title: "Basic Details",
			icon: RiMailLine,
			description: "Set your campaign name and schedule",
			fields: (
				<div className='space-y-4'>
					<div>
						<label className={`block mb-2 ${theme.text.secondary}`}>
							Campaign Name
						</label>
						<input
							type='text'
							name='name'
							value={formData.name}
							onChange={handleInputChange}
							className={`w-full p-3 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border focus:ring-2 focus:ring-primary/50 transition-all`}
							placeholder='Enter campaign name'
							required
						/>
					</div>
					<div>
						<label className={`block mb-2 ${theme.text.secondary}`}>
							Send Date
						</label>
						<input
							type='datetime-local'
							name='sendDate'
							value={formData.sendDate}
							onChange={handleInputChange}
							className={`w-full p-3 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border focus:ring-2 focus:ring-primary/50 transition-all`}
							min={new Date().toISOString().slice(0, 16)}
							required
						/>
					</div>
				</div>
			),
		},
		{
			title: "Template Selection",
			icon: RiFileTextLine,
			description: "Choose your email template",
			fields: (
				<div className='space-y-6'>
					{templates.length === 0 ? (
						<div
							className={`p-4 rounded-lg ${theme.background.tertiary} text-center ${theme.text.secondary}`}>
							No templates available. Please create a template
							first.
						</div>
					) : (
						<div className='grid grid-cols-2 gap-4'>
							{templates.map((template) => (
								<div
									key={template.id}
									onClick={() =>
										handleTemplateSelect(template)
									}
									className={`relative cursor-pointer rounded-lg overflow-hidden border-2 transition-all ${
										selectedTemplate?.id === template.id
											? `${theme.accent.border} ring-2 ${theme.accent.ring}`
											: theme.border.primary
									} hover:${theme.accent.border}`}>
									{selectedTemplate?.id === template.id && (
										<div
											className={`absolute top-2 right-2 w-6 h-6 rounded-full ${theme.accent.background} flex items-center justify-center`}>
											<RiCheckLine className='w-4 h-4 text-white' />
										</div>
									)}
									<div className='aspect-video w-full overflow-hidden bg-gray-100'>
										<img
											src={template.preview}
											alt={template.name}
											className='w-full h-full object-cover'
											onError={(e) => {
												e.target.src =
													"/placeholder-template.png";
											}}
										/>
									</div>
									<div
										className={`p-4 ${theme.background.tertiary}`}>
										<h4
											className={`font-medium ${theme.text.primary} mb-1`}>
											{template.name}
										</h4>
										<p
											className={`text-sm ${theme.text.secondary}`}>
											{template.description ||
												"No description available"}
										</p>
									</div>
								</div>
							))}
						</div>
					)}

					{selectedTemplate && (
						<div
							className={`p-6 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border`}>
							<div className='flex justify-between items-start mb-4'>
								<div>
									<h4
										className={`font-medium ${theme.text.primary} text-lg mb-1`}>
										Selected Template:{" "}
										{selectedTemplate.name}
									</h4>
									<p className={`${theme.text.secondary}`}>
										{selectedTemplate.description}
									</p>
								</div>
								<button
									type='button'
									onClick={() =>
										window.open(
											`/templates/${selectedTemplate.id}/preview`,
											"_blank",
										)
									}
									className={`px-4 py-2 rounded-lg ${theme.accent.background} ${theme.text.primary} text-sm`}>
									Preview Full Template
								</button>
							</div>
							<div
								className={`grid grid-cols-3 gap-4 ${theme.text.secondary} text-sm`}>
								<div>
									<span className='font-medium'>
										Created:
									</span>
									<span className='ml-2'>
										{new Date(
											selectedTemplate.createdAt,
										).toLocaleDateString()}
									</span>
								</div>
								<div>
									<span className='font-medium'>
										Last Modified:
									</span>
									<span className='ml-2'>
										{new Date(
											selectedTemplate.updatedAt,
										).toLocaleDateString()}
									</span>
								</div>
								<div>
									<span className='font-medium'>Type:</span>
									<span className='ml-2'>
										{selectedTemplate.type || "Standard"}
									</span>
								</div>
							</div>
						</div>
					)}
				</div>
			),
		},
		{
			title: "Guest List",
			icon: RiUserLine,
			description: "Select your recipients",
			fields: (
				<div className='space-y-4'>
					<div>
						<label className={`block mb-2 ${theme.text.secondary}`}>
							Select Guest List
						</label>
						<select
							name='guestListId'
							value={formData.guestListId}
							onChange={handleInputChange}
							className={`w-full p-3 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border focus:ring-2 focus:ring-primary/50 transition-all`}
							required>
							<option value=''>Select a guest list</option>
							{guestLists.map((list) => (
								<option key={list.id} value={list.id}>
									{list.name} ({list.guests?.length || 0}{" "}
									recipients)
								</option>
							))}
						</select>
					</div>
					{formData.guestListId && (
						<div
							className={`p-4 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border`}>
							<h4
								className={`font-medium ${theme.text.primary} mb-2`}>
								Recipients Summary
							</h4>
							<div className={`${theme.text.secondary}`}>
								{(() => {
									const selectedList = guestLists.find(
										(list) =>
											list.id === formData.guestListId,
									);
									return selectedList ? (
										<div>
											<p>
												Total Recipients:{" "}
												{selectedList.guests?.length ||
													0}
											</p>
											<p>
												List Name: {selectedList.name}
											</p>
											{selectedList.description && (
												<p>
													Description:{" "}
													{selectedList.description}
												</p>
											)}
										</div>
									) : (
										<p>No list details available</p>
									);
								})()}
							</div>
						</div>
					)}
				</div>
			),
		},
		{
			title: "Email Settings",
			icon: RiGlobalLine,
			description: "Configure sending details",
			fields: (
				<div className='space-y-4'>
					<div>
						<label className={`block mb-2 ${theme.text.secondary}`}>
							Send From Domain
						</label>
						<select
							name='domainId'
							value={formData.domainId}
							onChange={handleInputChange}
							className={`w-full p-3 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border focus:ring-2 focus:ring-primary/50 transition-all`}
							required>
							<option value=''>Select a domain</option>
							{domains.map((domain) => (
								<option
									key={domain.id}
									value={domain.id}
									disabled={
										domain.usedToday >= domain.dailyLimit
									}>
									{domain.domain} ({domain.usedToday}/
									{domain.dailyLimit} emails today)
								</option>
							))}
						</select>
					</div>
					{formData.domainId && (
						<div
							className={`p-4 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border`}>
							<h4
								className={`font-medium ${theme.text.primary} mb-2`}>
								Domain Details
							</h4>
							<div className={`${theme.text.secondary}`}>
								{(() => {
									const selectedDomain = domains.find(
										(domain) =>
											domain.id === formData.domainId,
									);
									return selectedDomain ? (
										<div className='space-y-2'>
											<div className='flex items-center justify-between'>
												<span>Daily Usage:</span>
												<div className='flex items-center space-x-2'>
													<div className='w-32 h-2 bg-gray-700 rounded-full'>
														<div
															className='h-full bg-primary rounded-full'
															style={{
																width: `${
																	(selectedDomain.usedToday /
																		selectedDomain.dailyLimit) *
																	100
																}%`,
															}}
														/>
													</div>
													<span>
														{
															selectedDomain.usedToday
														}
														/
														{
															selectedDomain.dailyLimit
														}
													</span>
												</div>
											</div>
											<p>
												Sending Email:{" "}
												{selectedDomain.email}
											</p>
											<p>
												Last Used:{" "}
												{new Date(
													selectedDomain.lastUsed,
												).toLocaleDateString()}
											</p>
										</div>
									) : (
										<p>No domain details available</p>
									);
								})()}
							</div>
						</div>
					)}
					<div>
						<label className={`block mb-2 ${theme.text.secondary}`}>
							Email Subject
						</label>
						<input
							type='text'
							name='subject'
							value={formData.subject}
							onChange={handleInputChange}
							className={`w-full p-3 rounded-lg ${theme.background.tertiary} ${theme.border.primary} border focus:ring-2 focus:ring-primary/50 transition-all`}
							placeholder='Enter email subject'
							required
						/>
					</div>
				</div>
			),
		},
	];

	return (
		<div className='fixed inset-0 bg-black/50 flex items-center justify-center z-50'>
			<div
				className={`${theme.background.secondary} rounded-xl p-8 max-w-3xl w-full max-h-[90vh] overflow-y-auto`}>
				<div className='flex justify-between items-center mb-8'>
					<h2 className={`text-2xl font-bold ${theme.text.primary}`}>
						New Campaign
					</h2>
					<button
						onClick={onClose}
						className={`p-2 rounded-lg ${theme.text.secondary} hover:${theme.text.primary} hover:${theme.background.hover}`}>
						✕
					</button>
				</div>

				<div className='mb-8'>
					<div className='flex justify-between mb-8'>
						{steps.map((step, index) => {
							const StepIcon = step.icon;
							return (
								<div
									key={index}
									className={`flex items-center ${
										index < steps.length - 1 ? "flex-1" : ""
									}`}>
									<div
										className={`flex flex-col items-center ${
											currentStep === index + 1
												? ""
												: "opacity-50"
										}`}>
										<div
											className={`w-12 h-12 rounded-full flex items-center justify-center mb-2 ${
												currentStep > index + 1
													? theme.highlight.background
													: currentStep === index + 1
													? theme.accent.background
													: theme.background.tertiary
											}`}>
											<StepIcon className='w-6 h-6' />
										</div>
										<span
											className={`text-sm ${theme.text.secondary} text-center`}>
											{step.title}
										</span>
									</div>
									{index < steps.length - 1 && (
										<div
											className={`flex-1 h-1 mx-4 mt-6 ${
												currentStep > index + 1
													? theme.highlight.background
													: theme.background.tertiary
											}`}
										/>
									)}
								</div>
							);
						})}
					</div>
				</div>

				{error && (
					<div className='bg-red-500/10 border border-red-500/50 text-red-500 p-4 rounded-lg mb-6'>
						{error}
					</div>
				)}

				<form onSubmit={handleSubmit}>
					<div className='mb-8'>
						<div className='flex items-center mb-4'>
							<h3
								className={`text-xl font-medium ${theme.text.primary}`}>
								{steps[currentStep - 1].title}
							</h3>
							<span
								className={`ml-3 text-sm ${theme.text.secondary}`}>
								{steps[currentStep - 1].description}
							</span>
						</div>
						{steps[currentStep - 1].fields}
					</div>

					<div className='flex justify-between'>
						<button
							type='button'
							onClick={() => setCurrentStep(currentStep - 1)}
							disabled={currentStep === 1}
							className={`px-6 py-3 rounded-lg ${
								currentStep === 1
									? "opacity-50 cursor-not-allowed"
									: ""
							} ${theme.border.primary} border hover:${
								theme.background.hover
							} transition-colors`}>
							Previous
						</button>
						{currentStep < steps.length ? (
							<button
								type='button'
								onClick={handleNext}
								disabled={!stepValidation[currentStep]}
								className={`px-6 py-3 rounded-lg ${
									theme.highlight.background
								} ${theme.text.primary} ${
									!stepValidation[currentStep]
										? "opacity-50 cursor-not-allowed"
										: "hover:opacity-90"
								} transition-all`}>
								Next
							</button>
						) : (
							<button
								type='submit'
								disabled={
									loading || !stepValidation[currentStep]
								}
								className={`px-6 py-3 rounded-lg ${
									theme.highlight.background
								} ${theme.text.primary} ${
									loading || !stepValidation[currentStep]
										? "opacity-50 cursor-not-allowed"
										: "hover:opacity-90"
								} transition-all`}>
								{loading ? "Creating..." : "Create Campaign"}
							</button>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default NewCampaignForm;
