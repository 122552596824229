import React, { Component } from "react";
import {
	motion,
	AnimatePresence,
	LazyMotion,
	domAnimation,
} from "framer-motion";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useParams,
} from "react-router-dom";
// import ElanRetinolPage from "./ElanRetinolPage";
// import Page404 from "./404Page";
import Login from "./Unauthed/Login";
// import ColorStayEvent from "./ColorStayEvent";
import RsvpExport from "./Dashboard";
import EventPage from "./EventPage";

import DashboardLogin from "./Dashboard/Unauthed/Login";
// import ColorStayReminder from "./ReminderPage";
import JarvisChat from "./BeautyBot/JarvisChat";
// import RsvpTemplate from "./RsvpTemplate";
import RsvpUrl from "./RsvpUrl";
import DashboardLayout from "./Dashboard/Layout/DashboardLayout";
import DashboardHome from "./Dashboard/Pages/Home";
import Campaigns from "./Dashboard/Pages/Campaigns";
import GuestLists from "./Dashboard/Pages/GuestLists";
import Templates from "./Dashboard/Pages/Templates";
import Analytics from "./Dashboard/Pages/Analytics";
import { ThemeProvider } from "./theme/ThemeContext";
import Domains from "./Dashboard/Pages/Domains";
import RSVPs from "./Dashboard/Pages/RSVPs";
import LandingPage from "./LandingPage";

// Wrapper component to get URL parameters
function EventPageWrapper() {
	const { eventId } = useParams();
	return <EventPage eventId={eventId} />;
}

const PrivateRoute = ({ children }) => {
	const accessToken = sessionStorage.getItem("accessToken");
	return accessToken ? children : <Navigate to='/login' />;
};

class ExampleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			urls: [
				{
					link: "comicconjar",
					title: "Comic con 8 Hour sample QR ",
				},
			],
		};
	}
	// comicconpinth
	render() {
		return (
			<LazyMotion features={domAnimation}>
				<ThemeProvider>
					<Router>
						<AnimatePresence mode='wait' initial={false}>
							<Routes>
								<Route
									path={`/`}
									element={
										<motion.div
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											transition={{ duration: 0.3 }}
											style={{
												willChange:
													"transform, opacity",
											}}>
											<LandingPage />
										</motion.div>
									}
								/>
								{/* <Route
									path={`/elanretinol`}
									element={<ElanRetinolPage />}
								/> */}

								{/* <Route
									path={`/colorstayatrockets`}
									element={<ColorStayEvent />}
								/> */}

								<Route
									path={`/rsvp/:eventId/:urlToken`}
									element={<RsvpUrl />}
								/>

								{/* New generic event route */}
								<Route
									path={`/event/:eventId`}
									element={<EventPageWrapper />}
								/>

								{/* Dashboard for exporting of RSVPS's */}
								<Route
									path={`/rsvpdata/:eventId/:urlToken`}
									element={<RsvpExport />}
								/>
								{/* Dashboard for exporting of RSVPS's */}

								<Route
									path={`/login`}
									element={<DashboardLogin />}
								/>
								<Route
									path={`/dashboard`}
									element={<DashboardLogin />}
								/>

								{/* <Route
									path={`/colorstayatrockets/countdown`}
									element={<ColorStayReminder />}
								/> */}

								<Route
									path={`/julia/bot`}
									element={<JarvisChat />}
								/>

								{/* {this.state.urls.map((d, i) => (
									<Route
										path={`/${d.link}`}
										element={
											<EightHourComponent
												urlPath={"8 Hour comic con sample QR"}
											/>
										}
									/>
								))} */}

								{/* <Route
									path='/signupform'
									exact
									element={<SignupTracker />}
								/>

								<Route path='/thankyou' exact element={<ThankYou />} />

								<Route path='/entercomp' element={<Comp />} /> */}

								{/* <Route
									path={`/instagram`}
									element={<EightHourComponent urlPath={"instagram"} />}
								/>

								<Route
									path={`/instabuynow`}
									element={
										<EightHourComponent
											urlPath={"comic con instagram"}
										/>
									}
								/>

								<Route
									path={`/presskit`}
									element={
										<EightHourComponent
											urlPath={"8 Hour presskit QR"}
										/>
									}
								/> */}

								{/* Dashboard Routes */}
								<Route
									path='/dashboard/*'
									element={
										<PrivateRoute>
											<DashboardLayout>
												<Routes>
													<Route
														path='/home'
														element={
															<DashboardHome />
														}
													/>
													<Route
														path='/campaigns'
														element={<Campaigns />}
													/>
													<Route
														path='/rsvps'
														element={<RSVPs />}
													/>
													<Route
														path='/guests'
														element={<GuestLists />}
													/>
													<Route
														path='/templates'
														element={<Templates />}
													/>
													<Route
														path='/analytics'
														element={<Analytics />}
													/>
													<Route
														path='/domains'
														element={<Domains />}
													/>
												</Routes>
											</DashboardLayout>
										</PrivateRoute>
									}
								/>

								{/* Redirect root to dashboard */}
								<Route
									path='/'
									element={
										<Navigate
											to='/dashboard/home'
											replace
										/>
									}
								/>

								<Route path='*' element={<Navigate to='/' />} />
							</Routes>
						</AnimatePresence>
					</Router>
				</ThemeProvider>
			</LazyMotion>
		);
	}
}

export default ExampleComponent;
